.button {
	cursor: pointer;
	padding: 13px 0;
	width: 203px;
	background: $background;
	@include border();
	position: relative;
	z-index: 100;
	@include font_small();
	font-family: 'IBMPlexSans';
	font-weight: 700;

	&:hover(:not(:disabled)){
		color: $active;
	}

	&:focus{
		color: #F89500;
	}

	@media (max-width: $mobile) {
		width: 100%;;
	}

	&__full{
		@include font(14px, 20px, 600);
		background: $active;
		font-family: 'OpenSans';
		width: 100%;
		min-width:203px;
		padding: 13px 0;
		box-shadow: -4.58874px -4.58874px 13.7662px #FFFFFF, 4.58874px 4.58874px 13.7662px rgba(174, 174, 192, 0.4);
		&:hover{
			color: $black;
			box-shadow: -6px -6px 13.7662px #FFFFFF, 6px 6px 13.7662px rgba(171, 171, 183, 0.6);
		}
	
		&:focus{
			color: $black;
			background: #F89500;
			box-shadow: -4.58874px -4.58874px 13.7662px #FFFFFF, 4.58874px 4.58874px 13.7662px rgba(174, 174, 192, 0.4);
		}
		
	}

	&__prev {
		background:$basic;
		box-shadow: -4.58874px -4.58874px 13.7662px #FFFFFF, 4.58874px 4.58874px 13.7662px rgba(174, 174, 192, 0.4);
		border-radius: 44.9697px;
		padding: 10px 17px;
		font-family: 'OpenSans';
		font-weight: 400;
		@include flex(space-between);
		margin-bottom: 30px;
		

		&::before {
			content: "";
			display: inline-block;
			@include icon("../../assets/arrow-prev.svg");
			width: 12px;
			height: 12px;
			margin-right: 15px;

		}

		@media (max-width: $tablet) {
			@include border();
			width: 40px;
			height: 40px;
			border: 50%;
			padding: 0;
			&::before {
				display: block;
				content: "";
				display: inline-block;
				@include icon("../../assets/arrow-prev.svg");
				width: 16px;
				height: 16px;
				margin: auto;
	
			}
		}

		@media (max-width: $mobile) {
			margin-bottom: 30px;
			border-radius: 44.9697px;
			padding: 10px 17px;
			position: relative;
			width: auto;
			height: auto;
			&::before {
				content: "";
				display: inline-block;
				@include icon("../../assets/arrow-prev.svg");
				width: 12px;
				height: 12px;
				margin-right: 15px;
	
			}
		}
	}

	&__submit {
		width: 100%;
		padding: 13px 0;
		background: $active;
		box-shadow: -4.58874px -4.58874px 13.7662px #FFFFFF, 4.58874px 4.58874px 13.7662px rgba(174, 174, 192, 0.4);
		border-radius: 44.9697px;
		font-family: 'OpenSans';
		&:hover(:not(:disabled)){
			color: $black;
			box-shadow: -6px -6px 13.7662px #FFFFFF, 6px 6px 13.7662px rgba(171, 171, 183, 0.6);
		}


	
		&:focus{
			color: $black;
			background: #F89500;
			box-shadow: -4.58874px -4.58874px 13.7662px #FFFFFF, 4.58874px 4.58874px 13.7662px rgba(174, 174, 192, 0.4);
		}

		

		&--continue{
			max-width: 253px;
			box-shadow: none;
		}
	}

	&__empty {
		@include font_small(14px, 18px, 400);
		font-family: 'OpenSans';
		width: 100%;
		padding: 12px 0;
		border: 2px solid #FFC212;
		background-color: $background;
		box-shadow: inset 0.333328px 0.333328px 1.33331px rgba(0, 0, 0, 0.19534);
		&:hover{
			color: $black;
			border: 2px solid #FFC212;
		}
	
		&:focus{
			color: $black;
			border: 2px solid #F89500;
		}
	}

	&__icon {
		position: absolute;
		right: 2%;
		top: 30%;
		background: white;
		padding: 0;
	}

	&__remind {
		display: block;
		@include font_small();
		color: $gray;
		font-weight: 600;
		text-decoration: underline;
		margin-left: auto;
	}

	&__exit{
		width: 103px;
		color: #919191;
	}

	&__control {
		width: auto;
		border: none;
		box-shadow: none;
		padding: 9px 16px;
	}

	&__excel{
		border: 2px solid #00BD62;
		border-radius: 60px;
		@include font_small(12px, 17px, 400);
		white-space: nowrap;
		padding: 10px 18px;
		width: auto;
	}

	&__active-tariff{
		background: #EBEBEB;
		color:  #919191;
		padding: 13px 0;
		width: 203px;
		background: $background;
		@include border();
		position: relative;
		z-index: 100;
		@include font_small();
		font-weight: 600;
		cursor: auto;
	}

	&__table{
		width: 126px;
    	padding: 10px 0;
		background: $active;
		font-family: 'OpenSans';
		font-weight: 600;
		color: $black;
		@include font_small(12px, 16px, 400);

		&:hover{
			color: $black;
			box-shadow: -6px -6px 13.7662px #FFFFFF, 6px 6px 13.7662px rgba(171, 171, 183, 0.6);
		}
	
		&:focus{
			color: $black;
			background: #F89500;
			box-shadow: -4.58874px -4.58874px 13.7662px #FFFFFF, 4.58874px 4.58874px 13.7662px rgba(174, 174, 192, 0.4);
		}

		&:disabled {
			box-shadow:none;
		}
	}

	&__sign{
		width: 180px;
		@include font_small(14px, 19.8px, 600);
		background: $active;
		&:hover{
			color: $black;
			box-shadow: -6px -6px 13.7662px #FFFFFF, 6px 6px 13.7662px rgba(171, 171, 183, 0.6);
		}
	
		&:focus{
			color: $black;
			background: #F89500;
			box-shadow: -4.58874px -4.58874px 13.7662px #FFFFFF, 4.58874px 4.58874px 13.7662px rgba(174, 174, 192, 0.4);
		}
	}

}


@media (max-width: $tablet) {
	.button{
		&__excel{
			padding: 10px;
			text-align: center;
		}
	}
}

@media (max-width: $mobile) {
	.button{
		&__control {
			padding: 0px 11px;
			display: flex;
		}

		&__excel{
			padding: 10px 5px;
		}
		
		&__empty{
			width: 99%;
		}

		&__prev{
			position: relative;
			margin-bottom: 38px;
		}
	}
}

