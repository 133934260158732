.new-contact{
	max-width: 780px;
	margin: auto;
	position: relative;

	&__wrap{
		padding: 77px 93px 55px;
		@include border();
		border-radius: 26px;
	}

	&__blocks{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}

	&__title{
		@include h2();
		font-family: 'Exo2';
		font-weight: 700;
		max-width: 65%;
		margin-bottom: 48px;
	}

	&__item-user{
		width: 30%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
	}

	&__item{
		width: 100%;
		margin-bottom: 20px;
	}

	&__select-block{
		margin-bottom:40px ;
		width: 100%;



		& .css-b62m3t-container{
			margin: 0;
			width: 100%;
		}
	}

}

.remove-contact{
	max-width: 480px;
	margin: auto;

	&__wrap{
		@include border();
		padding: 121px 70px 85px;
		border-radius: 26px;
		position: relative;
	}


	&__title{
		@include h2();
		font-family: 'Exo2';
		font-weight: 700;
		text-align: center;
		margin-bottom: 20px;
		white-space: nowrap;
	}

	&__text{
		@include font();
		font-weight: 400;
		margin-bottom: 30px;
		text-align: center;
	}

	& .button:first-of-type {
		margin-bottom: 20px;
	}
}

@media (max-width: $tablet) {
	.new-contact{
		.select-block__item{
			display: block;
		}
	}

	.remove-contact{
		max-width: 580px;
		&__wrap{
			height: 100%;
			padding: 121px 40px 50px;
		}
	
	
		&__title{
			@include h3();
		}
	
		&__text{
			margin-bottom: 50px;
		}
	}

}

@media (max-width: $mobile) {
	.new-contact{
		margin: 0 auto;
		
		

		&__wrap{
			display: flex;
			flex-direction: column;
			height: inherit;
			padding: 80px 20px 30px;
		}

		&__title{
			@include h4();
			max-width: 100%;
			margin-bottom: 30px;
		}

		&__item-user{
			width: 45%;
			 &:nth-child(3){
				width: 100%;
			 }
			
		}
	

	}

	.remove-contact{
		width: 100%;
		max-width: none;
		height: inherit;
		
		&__wrap{
			
			height: inherit;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			padding: 0;
		}
	
		&__body{
			padding-bottom: 20px;
		}
	
		&__title{
			@include h3();
		}
	
		&__text{
			margin-bottom: 224px;
		}
	}

}