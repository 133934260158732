.registration {
  &__wrap {
    padding: 80px 0 50px;
  }

  &__main {
    position: relative;
    z-index: 100;
    @include flex(space-between, flex-start);
  }

  &__welcome {
    @include h1();
    margin-bottom: 70px;
    max-width: 380px;
    font-style: normal;
  }

  &__list-title {
    @include font(18px, 18px, 700);
    margin-bottom: 45px;
  }

  &__сontent {
    position: relative;
    z-index: 10;
    @include flex(space-between, flex-start);
  }

  &__left-content {
    width: 95%;
  }

  &__right {
    max-width: 680px;
    width: 100%;
  }

  &__center {
    max-width: 460px;
    width: 100%;
    margin: auto;
  }

  &__forgot-pass {
    max-width: 460px;
    width: 100%;
    margin: 0 auto;
  }

  &__block {
    padding: 40px 100px 40px 40px;
    @include border();
    border-radius: 26px;
  }

  &__block--form {
    padding: 40px;
    @include border();
    border-radius: 26px;
  }
  &__title-wrap {
    margin-bottom: 60px;
    max-width: 67%;
  }

  &__title {
    @include font(24px, 30px, 300);
    font-family: "Exo2";

    &--light {
      font-weight: 300;
    }
    &--bold {
      font-weight: 800;
    }
  }

  &__separator {
    height: 4px;
    width: 100%;
    background: #f0f0f3;
    box-shadow: -0.2px -0.5px 1px #ffffff,
      0.5px 0.75px 1px rgba(174, 174, 192, 0.6);
    border-radius: 2px;
  }

  &__button {
    margin-top: 50px;
    @include flex(space-between);
  }

  &__link {
    max-width: 203px;
    @include font(16px, 19.2px, 700);
    color: #3d3d3d;
    font-family: "Exo2";
    position: relative;
    align-items: center;
    display: flex;
    cursor: pointer;

    & svg {
      width: 24px;
      height: 10px;
    }

    &:hover path {
      stroke: $active;
    }
    &:focus path {
      stroke: #f89500;
    }
  }

  &__sidebar {
    max-width: 380px;
  }
  &__sidebar-title {
    @include h2();
    font-weight: 700;
    font-family: "Exo2";
    margin-bottom: 30px;
    max-width: 380px;
  }

  &__sidebar-text {
    @include font_small();
    font-family: "OpenSans";
    font-weight: 400;
    max-width: 365px;
  }

  &__successful {
    @include border();
    border-radius: 26px;
  }
  &__successful-wrap {
    padding: 195px 40px 38px;
    text-align: center;
  }

  &__successful-title {
    @include font(24px, 24px, 700);
    margin-bottom: 70px;
    font-family: "Exo2";
  }

  &__successful-text {
    @include font_small();
    margin-bottom: 196px;
  }

  &__list-item:not(:last-child) {
    margin-bottom: 30px;
  }
}

.verification {
  &__wrap {
    padding: 80px 0;
  }

  &__block {
    padding: 57px 123px 38px 57px;
    @include border();
    border-radius: 26px;
  }

  &__main {
    max-width: 580px;
    width: 100%;
    margin: auto;
    z-index: 100;
    position: relative;
  }

  &__title {
    @include h2();
    margin-bottom: 29px;
    max-width: 80%;
    font-family: "Exo2";
    font-weight: 700;
  }

  &__text {
    @include font_small();
    margin-bottom: 48px;
    max-width: 90%;
    font-weight: 400;
  }

  &__content {
    margin-bottom: 60px;
  }

  &__button {
    margin-top: 50px;
    @include flex(flex-end);
    align-items: center;
  }

  &__link {
    max-width: 180px;
    @include font();
    font-family: "Exo2";
    font-weight: 700;
    position: relative;
    margin-right: 24px;

    &::after {
      position: absolute;
      content: "";
      @include icon("../../assets/arrow-next.svg");
      width: 24px;
      height: 10px;
      right: -25px;
      top: 30%;
    }
  }

  &__steps {
    @include flex(space-between);
    margin: auto;
  }

  &__line {
    width: 408px;
    margin-left: -24px;
  }

  &__step:not(:first-child) {
    .verification__step-point {
      position: relative;
      &::after {
        content: "";
        display: inline-block;
        width: 160px;
        height: 1px;
        background-color: #919191;
        position: absolute;
        right: 100%;
        top: 50%;
      }
    }
  }

  &__step {
    @include flex();
    flex-direction: column;
  }

  &__step-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: #919191;
    @include font_small();
    font-weight: 400;
    margin-top: 10px;
  }

  &__step-point {
    width: 22px;
    height: 22px;
    position: relative;
    border-radius: 50%;
    display: flex;
    align-items: center;
    z-index: 0;

    &::before {
      content: "";
      display: block;
      width: 8.7px;
      height: 8.7px;
      border-radius: 50%;
      background-color: #919191;
    }
  }

  &__step._active {
    .verification__step-point {
      position: relative;
      z-index: 100;
      &::before {
        content: "";
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: $active;
        box-shadow: inset 0.333328px 0.333328px 1.33331px rgba(0, 0, 0, 0.19534);
      }
    }

    &:not(:first-child) .verification__step-point {
      display: block;
      position: relative;
      &::after {
        content: "";
        display: inline-block;
        width: 156px;
        height: 1px;
        background-color: $active;
        position: absolute;
        right: 100%;
        top: 50%;
      }
    }

    .verification__step-content {
      font-weight: 700;
      color: $black;
    }
  }
}

@media (max-width: $tablet) {
  .registration {
    &__main {
      flex-direction: column;
    }

    &__welcome {
      margin-bottom: 50px;
      @include font(40px, 37.6px, 700);
      font-family: "Exo2";
    }

    &__сontent {
      flex-direction: column;
    }

    &__lest-content {
      margin-top: 70px;
      width: 100%;
    }

    &__right {
      margin: auto;
    }

    &__list {
      column-count: 2;
    }

    &__list-item {
      max-width: 280px;
      width: 95%;
    }

    &__title {
      @include font(22px, 28.6px, 300);
      font-family: "Exo2";

      &--light {
        font-weight: 300;
      }
      &--bold {
        font-weight: 600;
      }
    }

    &__left {
      order: 1;
      margin: auto;
    }

    &__left-content {
      width: 93%;
    }

    &__block {
      margin-bottom: 70px;
      padding: 40px;
    }

    &__left1 {
      order: 0;
      margin-bottom: 40px;
    }

    &__sidebar {
      flex-wrap: wrap;
      display: flex;
      max-width: 97%;
    }

    &__sidebar-title {
      min-width: 496px;
      font-size: 32px;
      line-height: 32px;
    }

    &__sidebar-text {
      max-width: 390px;
    }
  }

  .verification {
    &__main {
      max-width: 460px;
    }

    &__block {
      padding: 81px 35px 38px 40px;
    }

    &__title {
      font-size: 32px;
    }

    &__text {
      max-width: 95%;
    }
  }
}

@media (max-width: $mobile) {
  .registration {
    &__welcome {
      @include h2();
      font-family: "Exo2";
    }

    &__back {
      position: relative;
    }

    &__block,
    &__block--form {
      padding: 20px;
    }

    &__title-wrap {
      max-width: 95%;
      margin-bottom: 40px;
    }

    &__title {
      @include font(20px, 26px, 300);
      font-family: "Exo2";

      &--bold {
        font-weight: 600;
      }
    }

    &__list {
      column-count: 1;
    }

    &__list-item {
      max-width: 90%;
    }

    &__button {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 30px;

      a:first-of-type {
        width: 100%;
      }
    }

    &__link {
      @include font_small(14px, 16.8px, 700);
      font-family: "Exo2";
      max-width: 100%;
      margin: 25px 0 10px;
      align-items: flex-start;
      &:after {
        right: -35px;
        top: 25%;
      }
    }

    &__sidebar-title {
      font-size: 26px;
      line-height: 26px;
      margin-bottom: 20px;
      min-width: 350px;
    }

    &__left {
      margin: inherit;
    }

    &__left-content {
      width: 100%;
    }

    &__successful-wrap {
      padding: 195px 20px 28px;
    }

    &__successful-title {
      @include font(20px, 20px, 700);
      font-family: "Exo2";
    }

    &__successful-text {
      max-width: 250px;
      margin: auto;
      margin-bottom: 196px;
    }
  }

  .verification {
    &__main {
      max-width: max-content;
      min-width: 340px;
    }

    &__block {
      padding: 30px 20px 18px;
    }

    &__title {
      font-size: 26px;
    }

    &__line {
      max-width: 290px;
      margin-left: 0;
    }

    &__step:not(:first-child) {
      .verification__step-point {
        &::after {
          width: 100px;
        }
      }
    }

    &__button {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      a {
        width: 100%;
      }
    }

    &__link {
      margin: 32px 0 18px;
      max-width: 140px;
    }
  }
}
