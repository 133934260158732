.tariff-plan {
	position: relative;
	max-width: 980px;
	margin: auto;
	display: flex;
	
	
	&__plan{
		width: 980px;
		@include border();
		background-color: $background;
	}

	&__wrap{
		padding: 73px 90px 54px 60px;
	}

	&__title{
		@include font(30px, 30px, 700);
		font-family: 'Exo2';
		margin-bottom: 104px;
		
	}

	// &__table{
	// 	box-shadow: none;
	// 	border-radius: 0;
	// }

	// &__thead{
	// 	border-bottom: none;
	// }

	// &__thead-th{
	// 	width: 280px;
	// 	@include font();
	// 	font-weight: 700;
	// 	color: $black;
	// 	border-right: 1px solid rgb(206, 209, 217);
	// }

	// &__th{
	// 	text-align: start;
	// 	@include font_small();
	// 	font-weight: 700;
	// 	color: $black;
	// 	border-bottom: 0;
	// 	border-right: 1px solid rgb(206, 209, 217);
	// }

	// &__td{
	// 	text-align: center;
	// 	border-left: none;
	// 	border-right: 1px solid rgb(206, 209, 217);
	// 	padding: 20px 0;
	// }

	// &__body tr:first-child td, &__body tr:first-child th{
	// 	padding-top: 66px;
	// }

	// &__thead-th:last-of-type{
	// 	position: relative;

	// 	&::before{
	// 		content: '';
	// 		position: absolute;
	// 		right: 3%;
    // 		top: 15%;
	// 		@include icon('../../assets/tooltip.svg');
	// 		width: 18px;
	// 		height: 18px;
			
	// 	}
	// }

	&__sent{
		width: 480px;
		margin: auto;
		@include border();
		background-color: $background;
		position: relative;
	}

	&__sent-wrap{
		padding: 102px 44px 40px 44px;
	}

	&__sent-title{
		text-align: center;
		@include h3();
		font-weight: 700;
		font-family: 'Exo2';
		color: $black;
		margin-bottom: 30px;
	}

	&__sent-text{
		text-align: center;
		@include font_small();
		font-weight: 400;
		color: $black;
		margin-bottom: 20px;
	}

	&__sent-text:last-of-type{
		margin-bottom: 50px;
	}


	&__сontent{
		display: flex;
		justify-content: space-between;
    	align-items: flex-start;
	}


	&__block{
		flex-grow: 1;

		&:first-child {
			margin-top: 85px;
		}
		&:not(:first-child){
			text-align: center;
			border-left: 1px solid #CED1D9;
		}
	}

	&__block-heading{
		@include font(16px, 20px, 700);
		margin-bottom: 66px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		& .tooltip:hover .tooltip__text{
			display: block;

		}

		path{
			fill: $black;
			
		}
	}

	&__block-title{
		@include font(14px, 20px, 700);
		margin-bottom: 40px;
		&-tb{
			display: none;
		}
	}

	&__block-item{
		@include font(14px, 20px, 400);
		margin-bottom: 40px;
		position: relative;

		&:last-child{
			margin-bottom: 68px;
		}

		& span{
			position: absolute;
			bottom: -20px;
			left: 25%;
			@include font(12px, 16.8px, 400);
			opacity: 0.4;
		}
	}


	
}

@media (max-width: $tablet) {
	.tariff-plan {
		max-width: 580px;

		&__plan{
			width: 580px;
		}

		&__wrap{
			padding: 70px 44px 49px;
		}

		&__title{
			margin-bottom: 60px;
			font-size: 24px;
			line-height: 24px;
		}

		// &__th{
		// 	display: none;
		// }

		// &__thead td{
		// 	display: none;
		// }

		// &__td:last-child{
		// 	border-right: none;
		// }

		// &__body tr:last-child td:first-child{
		// 	display: none;
		// }

		// &__thead-th{
		// 	text-align: left;
		// 	max-width: 149px;
		// 	white-space: break-spaces;
		// 	border-left: none;
		// 	&:last-of-type{
		// 		padding-left: 50px;
		// 		max-width: 100px;
		// 		border-right:none;
		// 		display: flex;
		// 		&:before{
		// 			right: -40%;
		// 			top: 10%;
		// 		}
		// 	}

			
		// }

		// &__td{
		// 	text-align: left;
		// 	padding-right: 54px;
		// 	&:last-child{
		// 		padding-left: 50px;
		// 	}

		// 	& button{
		// 		max-width: 180px;
		// 	}
		// }

		&__sent{
			max-width: 580px;
			width: 100%;
		}

		&__sent-wrap{
			padding: 82px 40px 40px;
		}

		&__сontent{
			align-items: flex-start;
		}

		&__block{
			
			&:not(:first-child){
				text-align: left;
			}
			&:first-child{
				display: none;
			}
			&:nth-child(2){
				border-left: none;
				padding-right: 54px;
			}

			&:last-child{
				padding-left: 54px;
				& .tariff-plan__block-heading{
					margin-bottom: 26px;
				}
			}

			& .button{
				max-width: 180px;
				padding: 12px 0;
			}
		}

		&__block-heading{
			justify-content: flex-start;
			align-items: flex-start;
			margin-bottom: 46px;

			& .tooltip{
				width: 35%;
				justify-content: flex-start;
			}
			
		}

		&__block-title{
			margin-bottom: 36px;
			&-tb{
				display: block;
				@include font_small(12px, 20px, 700);
				color: #9B9B9B;

			}
		}

		&__block-item{
			& span{	
				left:0;
			}
		}
	
	}
}

@media (max-width: $mobile) {
	.tariff-plan {
		bottom: 0;
		right: 0;
		top: 0;
		left: 0;
		position: fixed;
		width: 100%;
		max-width: 100%;
		height: 100vh;
		overflow: hidden;

		&__plan{
			width: 100%;
			height: 100%;
			border-radius: 0;
			box-shadow: none;
		}

		&__wrap{
			height: inherit;
			padding:0 20px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&__sent{
			width: 100%;
			height: inherit;
			border-radius: 0;
		}

		&__sent-wrap{
			padding: 0 20px;
			display: flex;
			flex-direction: column;
			height: inherit;
			justify-content: space-between;

			& .button{
				margin-bottom: 40px;
			}
		}

		&__sent-content{
			height: inherit;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}

		&__block{
			padding-left: 0;
			flex-direction: column;
			align-items: center;
			justify-content: space-evenly;
			height: inherit;
				&:first-child{
					display: flex;
					margin-top: 0;
				}
				&:last-child{
					padding-left: 0;
					& .tariff-plan__block-heading{
					margin-bottom:60px;
					}
				}
				
				& .button{
					margin-bottom: 40px;
				}

				
		}

		&__сontent{
			height: inherit;
			flex-grow: 1;
		}

		&__title{
			padding-bottom: 86px;
			padding-top: 77px;
			margin: 0;
		}

		&__block-heading{
			justify-content: center;
			font-size: 20px;
			margin-bottom: 75px;
			white-space: nowrap;
			&:first-child{
				margin-bottom: 80px;
			}
		}

		&__block-item{
			margin-bottom: 30px;
			&:last-child{
				margin-bottom: 50px;
			}

			& span {
				bottom: -20px;
				left: -9px;
				white-space: nowrap;
			}
		}
		
		.swiper-pagination{
			display: flex;
			justify-content: center;
    		align-items: center;
		}

		.swiper-pagination-bullet{
			width: 13px;
			height: 13px;
			border: 2px solid #9B9B9B;	
			border-radius: 50%;
			box-shadow: inset 0.333328px 0.333328px 1.33331px rgba(0, 0, 0, 0.19534);
		  &-active{
			  width: 15px;
			  height: 15px;
			  background: #F89500;
			  border: none;
		  }
		}
	}
}