.calendar {
  overflow: hidden;
  overflow-x: auto;
  padding: 0 8px;
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  }

  &__heading {
    display: flex;
    justify-content: flex-start;
    margin-top: 0;
  }

  &__heading-wrap {
    background: #f0f2f7;
    box-shadow: -1.66667px -1.66667px 5px #ffffff,
      2.5px 2.5px 5px rgba(174, 174, 192, 0.4);
    border-radius: 8.33333px;
    width: 200px;
    @include flex();
  }

  &__heading-month {
    @include font(14px, 20px, 700);
    font-weight: 700;
    color: $black;
    position: relative;
    display: flex;
    align-items: center;
    padding: 13px 16px;
    white-space: nowrap;

    &::before {
      content: "";
      display: block;
      height: 30.67px;
      width: 1.67px;
      background: #f0f2f7;
      box-shadow: -0.333333px -0.833333px 1.66667px #ffffff,
        0.833333px 1.25px 1.66667px rgba(174, 174, 192, 0.6);
      border-radius: 3.33333px;
      position: absolute;
      left: 0;
    }
    &::after {
      content: "";
      display: block;
      height: 30.67px;
      width: 1.67px;
      background: #f0f2f7;
      box-shadow: -0.333333px -0.833333px 1.66667px #ffffff,
        0.833333px 1.25px 1.66667px rgba(174, 174, 192, 0.6);
      border-radius: 3.33333px;
      position: absolute;
      right: 0;
    }
  }

  &__heading-control {
    @include flex();
    width: 100%;
    height: 100%;
    cursor: pointer;
    & .icon-arrow-calendar {
      @include icon("../../assets/arrow-calendar.svg");
      width: 10px;
      height: 13px;
      &--prev {
        transform: rotate(180deg);
      }
    }
  }

  &__legend {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin: 0 -10px;
  }

  &__week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin: 0 -10px -8px;
  }

  &__legend-item {
    margin: 0 5px;
    width: 100px;
  }

  &__legend-day,
  &__week-day {
    @include font(14px, 20px, 700);
    font-weight: 700;
    color: #919191;
    opacity: 0.5;
  }

  &__week-item {
    background: #f0f2f7;
    box-shadow: -1.07221px -1.07221px 3.21664px #ffffff,
      1.07221px 1.07221px 3.21664px rgba(174, 174, 192, 0.4);
    border-radius: 16px;
    height: 100px;
    width: 125px;
    margin: 10px 5px;
    padding: 14px 0 14px 10px;
  }

  &__week-item-wrap {
    height: inherit;
    @include flex(space-between, flex-start);
    flex-direction: column;
  }

  &__day-value {
    @include font(14px, 20px, 800);
    & span {
      @include font_small(12px, 20px, 600);
      color: #9b9b9b;
    }
  }
}

@media (max-width: $laptop) {
  .calendar {
    &__week-item {
      width: 100px;
    }
  }
}

@media (max-width: 1350px) {
  .calendar {
    &__heading {
      justify-content: start;
    }
  }
}

@media (max-width: $tablet) {
  .calendar {
    padding: 0 3px;
    margin-right: -40px;

    &__legend-item {
      margin: 0 10px;
    }

    &__week-item {
      margin: 10px;
    }

    &__week {
      margin: 0 -12px -5px;
      width: 100%;
    }
  }
}

@media (max-width: $mobile) {
  .calendar {
    margin-right: -10px;

    &__legend {
      margin: 0 -5px;
    }
    &__legend-item {
      margin: 5px 5px 12px 5px;
    }

    &__week {
      margin: -10px -7px -5px;
    }

    &__week-item {
      margin: 10px 5px;
    }

    &__heading {
      width: 100%;
      margin-bottom: 20px;
    }

    &__heading-wrap {
      width: 100%;
      justify-content: space-between;
    }

    &__heading-control {
      width: 37px;
    }

    &__heading-month {
      flex-grow: 1;
      justify-content: center;
    }
  }
}
