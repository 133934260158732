.loader {
  max-width: 1180px;
  width: 100%;
  height: 665px;
  margin: auto;
  position: relative;
  @include border();
  border-radius: 26px;
  background-color: rgba(255, 255, 255, 0.7);

  &__wrap {
    height: 100%;
  }

  &__title {
    text-align: center;
    @include h3();
    font-weight: 700;
    font-family: "Exo2";
    color: $black;
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__animation {
    width: fit-content;
    margin-top: 40px;
  }

  &__ball {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 20px;
    display: inline-block;
    position: relative;
    animation: jump 1s infinite;
    background-color: #797979;

    &-2 {
      animation-delay: 0.15s;
    }
    &-3 {
      animation-delay: 0.3s;
    }
    &-4 {
      animation-delay: 0.45s;
    }
    &-5 {
      animation-delay: 0.6s;
    }
  }
}

@media (max-width: $tablet) {
    .loader {
        max-width: 580px;
    }
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
