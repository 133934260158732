.filter-block {
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;

	&__wrap{
		width: 315px;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		background-color: #F0F2F7;
		box-shadow: inset -2px -2px 10px rgba(255, 255, 255, 0.9), inset 1px 1px 2px rgba(174, 174, 192, 0.2);
		border-radius: 5px;
		z-index: 1000;
	}

	&__content{
		padding: 51px 65px 0;
	}

	&__close{
		width: 46px;
		height: 46px;
		background: #F0F2F7;
		box-shadow: -1.75055px -1.75055px 5.25165px #ffffff, 1.75055px 1.75055px 5.25165px rgb(174 174 192 / 40%);
		border-radius: 47.1945px;
		position: absolute;
		right: -23px;
		top: 35px;
		z-index: 200;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	&__title{
		@include font(18px, 22px,800);
		font-family: 'Exo2';
		color: #9B9B9B;
		margin-bottom: 20px;
	}

	&__selects{
		margin-top: 46px;
		& .select-block{
			margin-top: 0;
			flex-direction: column;
			&__item{
				width: auto;
				margin: 0;
			}
		}

		& .css-4ljt47-MenuList {
			font-size: 14px;
			line-height: 20px;
			font-weight: 700;
			font-style: normal;
			font-family: "IBMPlexSans";
			color: #111111;
			text-align: left;
		}

		&  .css-qc6sy-singleValue {
			text-align: left;
		}
	}
}