.form {

	&__wrap {
		margin-top: 40px;
	}

	&__title {
		@include font(24px, 24px, 700);
		font-family: 'Exo2';
		margin-bottom:40px;
	}

	&__login-title{
		@include h2();
		font-weight:700 ;
		font-family: 'Exo2';
		margin-bottom: 40px;
	}

	&__text {
		@include font_small();
		max-width: 50%;
		margin-top: 20px;
		margin-bottom:100px;
	}

	&__field {
		@include flex(space-between);
	}

	&__field-user{
		max-width: 180px;
		width: 100%;
		margin-bottom: 30px;
	}

	&__field-contact{
		max-width: 285px;
		width: 100%;
		margin-bottom: 30px;
	}

	&__field-company,
	&__field-pass{
		width: 100%;
		margin-bottom: 30px;
	}

	&__field-signin {
		width: 100%;
		margin-bottom: 16px;

		&:first-child {
			margin-bottom:30px;
		}
	}


	&__login-contact{
		width: 100%;
		position: relative;

		&:first-child{
			margin-bottom: 30px;
		}

		&:last-child{
			margin-bottom: 16px;
		}
		
	}

	&__link-recovery{
    	margin-bottom: 40px;
		display: flex;
		justify-content: flex-end;
	}

	&__recovery-input {
		position: relative;
		margin-bottom: 40px;
	}

	&__password {
		margin-bottom: 30px;
		width: 100%;
		position: relative;
	}

	
}

@media (max-width: $tablet) {
	.form {

		&__login-title{
			font-size: 32px;
			line-height: 32px;
		}

		&__title {
			@include font(22px, 28.6px, 700);
			font-family: 'Exo2';
		}

		&__field {
			flex-wrap: wrap;
		}

		&__field-user {
			max-width: 230px;
			&:last-child{
				max-width: 100%;
			}
		}

		&__field-contact {
			max-width: 100%;
		}

		&__field-pass{
			margin-bottom: 26px;
		}
	}
}

@media (max-width: $mobile) {
	.form {
		&__login-title{
			font-size: 26px;
			line-height: 26px;
		}
		&__title {
			@include font(20px, 20px, 700);
			font-family: 'Exo2';
			margin-bottom:20px;
		}

		&__field {
			flex-wrap: wrap;
		}

		&__field-user,&__field-contact {
			max-width: 100%;
		}

		&__text{
			margin-bottom: 40px;
			max-width: 100%;
		}

		
		&__field-pass{
			margin-bottom: 20px;
		}
	}
}


.field__input {
	position:relative;
	
	.ant-input-suffix {
		position:absolute;
		right:15px;
		top:0;
		color: rgba(23,24,59,.5);
		
		&:hover .input-tooltop{
			opacity:1;
			overflow:visible;
		}
	}

}


.special-label{
	display: none;
}
