.chart {
  width: 100%;
  display: flex;

  &__yaxes {
    position: relative;
    background: $background;
    width: 75px;
    z-index: 2;
  }

  &__yaxes2 {
    position: relative;
    background: $background;
    width: 75px;
    z-index: 2;

    &-consumption {
      & + .chart__main {
        margin-left: -14px;
      }
    }
  }

  &__main {
    position: relative;
    width: 89%;
    overflow-x: auto;
    overflow-y: hidden;
    margin-left: -16px;
    cursor: pointer;

    &-consumption {
      margin-left: -10px;
    }

    &::-webkit-scrollbar {
      height: 5px;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ffc212;
      width: 5px;
      border-radius: 5px;
    }

    .chart__wrap {
      width: 100%;
      height: 253px !important;

      &-scroll {
        width: 100%;
      }
    }
  }

  &__askoe {
    position: relative;
    flex: 1;
    z-index: 1;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-left: -17px;
    .chart__wrap {
      width: 100%;
      height: 255px !important;
    }

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      width: 10px;
      background: transparent;
    }
  }

  &__askoe-wrap {
    background: #f0f2f7;
    box-shadow: inset -3.14286px -3.14286px 3.14286px rgba(255, 255, 255, 0.7),
      inset 3.14286px 3.14286px 6.28571px rgba(174, 174, 192, 0.2);
    border-radius: 15.925px;
    padding: 38px 20px 30px;
  }

  &__legend {
    margin-top: 20px;
    margin-left: 55px;
  }

  &__legend-list {
    @include flex();
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -30px -10px;
  }

  &__legend-item {
    @include font_small(14px, 20px, 400);
    font-family: "OpenSans";
    color: #4f4f4f;
    margin: 30px 10px;
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      box-shadow: inset 0.333328px 0.333328px 1.33331px rgba(0, 0, 0, 0.19534);
      border-radius: 18px;
      margin-right: 10px;
    }

    &--plan {
      span {
        width: 10px;
        height: 10px;
        background-color: #cacaca;
      }
    }

    &--fact {
      span {
        width: 10px;
        height: 10px;
        background-color: #ffc212;
      }
    }

    &--price {
      span {
        width: 30px;
        height: 4px;
        background-color: #d8d8d8;
      }
    }
  }

  &__bottom {
    width: 91%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--wrap {
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      & .general-info__chart-button {
        margin-left: 47px;
      }
    }
  }
}

@media (max-width: $laptop) {
  .chart {
    .chart__wrap {
      width: 150%;
      height: 253px !important;

      &-scroll {
        width: 150%;
      }
    }
  }
}

@media (max-width: $tablet) {
  .chart {
    &__yaxes {
      &:before {
        content: "Ціна грн/кВт*год";
        display: block;
        position: absolute;
        top: -20px;
        white-space: nowrap;
        @include font_small(10px, 14px, 400);
        color: #9b9b9b;
      }
    }

    &__yaxes2 {
      &:before {
        content: "Обсяг кВт*год";
        display: block;
        position: absolute;
        top: -20px;
        white-space: nowrap;
        @include font_small(10px, 14px, 400);
        color: #9b9b9b;
      }
    }
    &__main {
      position: relative;
      max-width: 1000px;
      .chart__wrap {
        width: calc(1000px - 55px);
      }
      &::after {
        content: "";
        position: sticky;
        bottom: 30px;
        right: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 87%;
        display: block;
        pointer-events: none;
        background: linear-gradient(
          to left,
          rgb(240, 242, 247, 0.8) 0,
          rgba(240, 242, 247, 0) 5%,
          rgba(240, 242, 247, 0) 50%,
          rgba(240, 242, 247, 0) 93%,
          rgba(240, 242, 247, 0.7) 99%
        );
      }
    }

    &__wrap {
      width: calc(1000px - 10%);
    }

    &__legend-item {
      @include font_small(12px, 16px, 400);
    }

    &__bottom {
      width: 100%;
      align-items: flex-start;
      flex-direction: column;
      &--wrap {
        & .general-info__chart-button {
          margin-left: 0;
        }
      }
    }

    &__askoe-wrap {
      border-radius: 0;
      box-shadow: none;
      padding: 38px 0 30px;
    }
  }
}

@media (max-width: $mobile) {
  .chart {
    &__main,
    &__askoe {
      position: relative;
      max-width: 700px;
      .chart__wrap {
        width: calc(700px - 40px);
      }
      &::after {
        content: "";
        position: sticky;
        bottom: 30px;
        right: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 87%;
        display: block;
        pointer-events: none;
        background: linear-gradient(
          to left,
          rgb(240, 242, 247, 0.8) 0,
          rgba(240, 242, 247, 0) 8%,
          rgba(240, 242, 247, 0) 50%,
          rgba(240, 242, 247, 0) 80%,
          rgba(240, 242, 247, 0.8) 100%
        );
      }
    }

    &__askoe-wrap {
      padding: 38px 0 0;
    }

    &__main {
      margin-left: -3px;
    }

    &__legend {
      margin: 0;
    }

    &__legend-list {
      align-items: flex-start;
      margin: 0;
      &--wrap {
        flex-direction: column;
      }
    }

    &__legend-item {
      margin: 8px;
    }

    &__yaxes {
      width: 78px;
    }

    &__yaxes2 {
      width: 78px;

      &-consumption {
        width: 57px;
        & + .chart__main {
          margin-left: -7px;
        }
      }

      &:before {
        left: -7px;
      }
    }
  }
}
