.order-energy{
	&__item{
		margin-top: 30px;
	}

	&__item-wrap{
		@include border();
		border-radius: 26px;
		padding: 30px 30px 30px 30px;
		position: relative;
	}

	&__item-contract{
		@include font_small(12px, 16.8px, 400);
		color: 919191;
		margin-bottom: 10px;
	}

	&__item-number{
		@include h3();
		font-weight: 700;
		font-family: 'Exo2';
		margin-bottom: 24px;
	}

	&__item-adress{
		@include font_small();
		color: #919191;
		margin-bottom: 10px;
	}

	&__item-point {
		position: absolute;
		right: 0;
		top: 0;
		width:100%;
		height: 100%;
		cursor: pointer;
	}
	&__item-arrow{
		margin: 30px 30px 0 auto;
		width: 30px;
		height: 30px;
		background: #F0F0F3;
		box-shadow: -2.34694px -2.34694px 7.04082px #FFFFFF, 2.34694px 2.34694px 7.04082px rgba(174, 174, 192, 0.4);
		border-radius: 36px;
		@include flex();
		cursor: pointer;


		&::before{
			content: '';
			@include icon('../../assets/arrow-open.svg');
			width: 10px;
			height: 10px;
			
		}

		&--open {
			&::before{
				transform: rotate(180deg);
			}
		}
	}

}


@media (max-width: $tablet) {
	.order-energy{
		& .select-block{
			&__item{
				display: none;
			}
		}
	}
}


@media (max-width: $mobile) {
	.order-energy{
		&__item-wrap{
			padding: 30px 20px;

		}

		&__item-number{
			font-size: 20px;
			line-height: 20px;
		}

		&__item-adress {
			font-size: 12px;
		}
	}
}
