.tariff-structure {
  @include border();
  background: #f0f2f7;
  box-shadow: -1.75055px -1.75055px 5.25165px #ffffff,
    1.75055px 1.75055px 5.25165px rgba(174, 174, 192, 0.4);
  border-radius: 26px;
  padding: 30px;

  &__heading {
    margin-bottom: 27px;
  }

  &__headings-wrap {
    @include flex(space-between, flex-start);
    flex-direction: column;
  }

  &__heading-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 27px;

    &--chart {
      margin-bottom: 0;
    }
  }

  &__heading-right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .tariff-structure__controls {
      margin-bottom: 24px;
      margin-left: auto;
    }
  }

  &__title {
    width: max-content;

    &--chart {
      width: 70%;
    }
  }

  &__title-wrap {
    margin-right: 15px;
    @include h3();
    color: $black;
    font-family: "Exo2";
    font-weight: 700;
  }

  &__controls {
    @include flex(space-between, center);
    background: #f0f2f7;
    box-shadow: -1.75055px -1.75055px 5.25165px #ffffff,
      1.75055px 1.75055px 5.25165px rgb(174 174 192 / 40%);
    border-radius: 7px;
    margin-left: 5px;

    &--chart {
      margin-left: 0;
    }
  }

  &__heading-info {
    max-width: 200px;
    @include font(16px, 20px, 700);
    font-family: "Exo2";
    color: $black;
  }
}

@media (max-width: $tablet) {
  .tariff-structure {
    padding: 40px 40px 54px;

    &__headings-wrap {
      align-items: flex-end;
    }

    &__heading-info {
      max-width: 196px;
    }

    &__heading-left {
      align-items: center;
    }

    &__title {
      width: 100%;
      margin-bottom: 20px;
    }

    &__title-wrap {
      @include font(32px, 32px, 700);
      font-family: "Exo2";
      max-width: 345px;
    }
  }
}

@media (max-width: $mobile) {
  .tariff-structure {
    padding: 30px 20px 20px;

    &__title-wrap {
      @include font(26px, 26px, 700);
      font-family: "Exo2";
    }

    &__heading-left .datepicker {
      margin-left: 0px;
    }

    &__heading-right {
      margin-top: 20px;
    }

    &__controls {
      margin-left: 0;
    }

    &__heading-info {
      max-width: 155px;
    }
  }
}
