.ellipse {
	overflow: hidden;
	height: 100%;
	width: 100%;
	position: absolute;
	top: -100px;
	right:0;
	z-index: 0;

	&::before{
		content: "";
		position: absolute;
    	right: -8%;
		top:-5%;
		@include icon("../../assets/ellipse.svg");
		height: 760px;
		width: 760px;
		transform: rotate(-15deg);
		@media (max-width: $tablet) {
			transform: rotate(26deg);
			height: 660px;
			width: 625px;
			right: -30%;
    		top: 28%;
		}
		@media (max-width: $mobile) {
			transform: rotate(26deg);
			height: 380px;
			width: 394px;
			right: -36%;
    		top: 25%;
		}
	}

	&--bottom {
		overflow: hidden;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		right:0;
		z-index: 0;
		&::before{
			content: "";
			position: absolute;
			@include icon("../../assets/ellipse1.svg");
			height: 700px;
			width: 760px;
			right: 0;
    		bottom: -13%;
			@media (max-width: $tablet) {
				transform: rotate(-5deg);
				height: 625px;
				width: 625px;
				right: -22%;
    			bottom: -13%;
				top: auto;
			}
			@media (max-width: $mobile) {
				transform: rotate(-29deg);
				height: 394px;
				width: 394px;
				right: -15%;
    			bottom: 0%;
				top: auto;
			}
		}
	}

}