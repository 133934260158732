.header{
	height: 100px;

	&__auth{
		padding-top: 40px;
		@include flex(flex-end);
	}

	&__non-auth{
		padding-top: 40px;
		@include flex(space-between);

		
	}

	&__logo {
		@include flex();
        @include size (180px, 48px);
        text-align: center;
		
	}

	&__logo-icon {
		@include icon("../../assets/logo.svg");
		@include size (100%, 100%);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}

	&__link-back {
		position: relative;
		z-index: 10;
		text-align: center;
	}

	&__buttons{
		margin-left: auto;
		display: flex;
	}

	&__controls {
		display: flex;
		align-items: center;
		@include border();
		border-radius: 7px;
		margin-left: 20px;
		padding: 0 8px;
	}


	&__separator{
		width: 2px;
		height: 34px;
		background: #F0F0F3;
		margin: 0 1px;
		box-shadow: -0.2px -0.5px 1px #FFFFFF, 0.5px 0.75px 1px rgba(174, 174, 192, 0.6);
		border-radius: 2px;
	}

	&__burger{
		display: none;
	}
	
	&__auth-logo{
		display: none;
	}
	
	
}

@media (max-width: $tablet) {
	.header {
		&__burger{
			display: block;
			margin-left: 24px;
		}
		 &__burger-wrap{
			width: 46px;
			height: 46px;
			@include border();
			border-radius: 7px;
			@include flex();
			svg{
				width: 20px;
				height: 20px;
			}
		 }

		&__auth-logo {
			display: block;
			svg{
				width: 180px;
				height: 46px;	
			}
		}

		// &__logo {
		// 	display: none;
		// }
		
		&__buttons .button__exit{
			display: none;
		}
	}
}

@media (max-width: $mobile) {
	.header {
		height: 69px;
		&__logo {
			max-width: 140px;
		}
		
		&__burger-wrap{
			height: 40px;
			width: 40px;

			svg{
				width: 24px;
			}
		}

		&__auth{
			padding-top: 29px;
		}

		&__link-back{
			font-size: 12px;
			padding: 10px 4px;
		}

		&__profile{
			display: flex;
		}

		&__auth-logo {
			svg{
				width: 140px;
				height: 36px;	
			}
		}
		
	}
}

