// //reset

// /* HTML5 display-role reset for older browsers */
// article, aside, details, figcaption, figure, footer, header, menu, nav, section {
// 	display: block;
//   }
  
  /** * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/) * http://cssreset.com */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre, a,
  abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center,
  dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, summary, time, mark,
  audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	vertical-align: top;
  }
  


  b, u, i, strong, em {
	vertical-align: baseline;
  }
  
  // html,
  // body {
	// text-size-adjust: 100%;
	// -moz-osx-font-smoothing: grayscale;
	// -webkit-font-smoothing:  antialiased;
  // }
  
  // *,
  // *:before,
  // *:after {
	// outline: none;
	// vertical-align: top;
	// box-sizing: border-box;
	// appearance: none;
	// -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  // }
  
  // table {
	// border-collapse: separate;
	// border-spacing: 0;
  // }
  
  // img,
  // video{
	// display: inline-block;
	// max-width: 100%;
	// height: auto;
  // }
  

  
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button , input[type="submit"],
  input[type="text"],input[type="email"],input[type="number"],input[type="phone"], input[type="phone"],  button, select{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
	border: 0;
  }


  
  
//   //additional
  
//   /*::-webkit-input-placeholder {}*/
  
//   /*::-moz-placeholder {}*/ /* firefox 19+ */
  
//   /*:-moz-placeholder {}*/ /* firefox 18- */
  
//   /*:-ms-input-placeholder {}*/ /* ie */