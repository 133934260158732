.main {
  &__wrap {
    padding: 19px 0 75px;
    &--none {
      display: none;
    }
  }

  &__content {
    width: 80%;

    margin-left: auto;
    position: relative;
    z-index: 100;

    &--open {
      width: 90%;
    }
  }

  &__header {
    margin-bottom: 39px;
  }

  &__title {
    @include h1();
    font-family: "Exo2";
    font-weight: 800;
    margin-bottom: 18px;
    width: 76%;

    &.full {
      width: 100%;
    }
  }

  &__text {
    @include font_small();
    width: 50%;
  }
}

@media (max-width: $laptop) {
  .main {
    &__content {
      width: 74.5%;

      &--open {
        width: 88%;
      }
    }
  }
}

@media (max-width: $tablet) {
  .main {
    &__title {
      font-size: 40px;
      line-height: 37.6px;
      width: 80%;
    }

    &__content {
      width: 100%;
    }

    &__text {
      width: 80%;
    }
  }
}

@media (max-width: $mobile) {
  .main {
    &__title {
      font-size: 36px;
      line-height: 36px;
    }
    &__text {
      width: 100%;
    }
  }
}
