.sidebar {
  position: absolute;
  left: 0;
  top: -100px;
  bottom: 0;

  &.show-sidebar {
    display: none;
    top: 110px;
  }

  &--close {
    width: 215px;
    transition: all 0.1s ease-in-out;
  }

  &__wrap {
    position: relative;
    height: 100%;
  }

  &__container {
    position: relative;
    height: 100%;
    padding: 40px 50px 0 117px;
    overflow: hidden;
    background: #f0f2f7;
    box-shadow: inset -2px -2px 10px rgba(255, 255, 255, 0.9),
      inset 1px 1px 2px rgba(174, 174, 192, 0.2);
    border-radius: 5px;
    transition: all 0.1s ease-in-out;
    z-index: 1;
  }

  &__ellipse {
    height: 800px;
    width: 800px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      left: 15%;
      bottom: -85%;
      @include icon("../../assets/ellipse.svg");
      transform: rotate(180deg);
    }
  }

  &__separator {
    width: 185px;
    height: 4px;
    @include border();
    border-radius: 2px;

    &--close {
      width: 28px;
      margin: auto;
    }
  }

  &__logo {
    @include flex();
    @include size(180px, 48px);
    text-align: center;
    margin-bottom: 32px;

    &--small {
      @include size(48px, 48px);
      align-items: flex-start;
      margin-bottom: 96px;
    }
  }

  &__logo-icon {
    @include icon("../../assets/logo.svg");
    @include size(100%, 100%);

    &--small {
      @include icon("../../assets/icon-mini.svg");
      @include size(100%, 100%);
    }
  }

  &__close {
    width: 46px;
    height: 46px;
    @include border();

    position: absolute;
    right: -23px;
    top: 35px;
    z-index: 200;
    @include flex();
    cursor: pointer;

    &--close {
      img {
        transform: rotate(180deg);
      }
    }
  }

  &__company-title {
    font-size: 18px;
    font-family: "Exo2";
    font-weight: 700;
    line-height: 21.6px;
    color: $gray_light;
    padding: 20px 0;
    max-width: 200px;
    white-space: normal;
  }

  &__list {
    padding: 30px 0;
  }

  &__list-item {
    @include font_small();
    font-weight: 700;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    cursor: pointer;

    &::before {
      content: none;
    }
    &:not(:last-child) {
      margin-bottom: 30px;
    }

    &--close {
      display: flex;
      align-items: center;

      &._active path {
        stroke: $active;
      }

      &._active._path path {
        stroke: $active;
        fill: $active;
      }

      &._active rect {
        stroke: $active;
      }
    }

    &._active a {
      color: $active;
    }
  }

  &__link {
    display: flex;
    align-items: flex-start;
    align-items: center;
  }

  &__dropdown-list-item {
    @include font_small();
    font-weight: 600;
    align-items: flex-start;
    flex-direction: column;
    margin: 11px 0 0 18px;
    padding: 0;

    &._active a {
      color: $active;
    }

    &::before {
      content: none;
    }
  }

  &__manager {
    @include font_small();
    margin-bottom: 10px;
  }

  &__contact {
    margin-bottom: 27px;
    @include font_small();
    font-weight: 700;
  }

  &__info {
    padding: 15px 0;
    position: relative;
    z-index: 1;
  }

  &__moderator {
    position: relative;
    padding: 30px 0;
    z-index: 1;
    cursor: pointer;

    &--close {
      @include flex();
      padding-top: 40px;
    }
  }

  &__moderator-title {
    margin-bottom: 10px;
    @include font_small();
  }

  &__moderator-name {
    @include font_small();
    font-weight: 700;
  }

  &__button {
    display: none;
  }

  &__phone {
    cursor: pointer;
  }
}

@media (max-width: $tablet) {
  .sidebar {
    width: 100%;
    box-shadow: none;
    border-radius: 0;

    &.tb-sidebar {
      display: none;
    }

    &.show-sidebar {
      display: block;
      top: 20px;
    }

    &__close,
    &__ellipse,
    &__logo {
      display: none;
    }

    &__wrap {
      margin: 0 94px 120px;

      & div:nth-child(3) {
        display: none;
      }
    }

    &__container {
      box-shadow: -1.66667px -1.66667px 5px #ffffff,
        2.5px 2.5px 5px rgb(174 174 192 / 40%);
      border-radius: 16px;
      padding: 50px 60px;
      height: auto;
      transition: all 0.1s ease-in-out;
      z-index: 1;
    }

    &__separator {
      width: 100%;
    }

    &__company-title {
      font-size: 24px;
      line-height: 24px;
      padding-top: 0;
    }

    &__button {
      display: block;
      margin-top: 40px;
      width: 100%;
      & .button {
        width: 100%;
      }
    }

    &__list {
      column-count: auto;
      padding: 40px 0;
      ul {
        column-count: auto;
      }
    }

    &__list-item {
      font-size: 24px;
      line-height: 24px;
    }

    &__dropdown-list-item {
      font-size: 16px;
    }

    &__info,
    &__moderator {
      padding: 40px 0;
      display: flex;
      justify-content: space-between;
    }
    &__manager,
    &__moderator-title {
      width: 45%;
      font-weight: 300;
    }

    &__info-person,
    &__moderator-name {
      width: 45%;
      font-weight: 700;
    }
  }
}

@media (max-width: $mobile) {
  .sidebar {
    &.show-sidebar {
      top: 25px;
    }

    &__wrap {
      margin: 0 17px 40px;
    }

    &__container {
      padding: 30px 20px;
    }

    &__company-title {
      font-size: 20px;
      line-height: 20px;
      padding-bottom: 24px;
    }

    &__list {
      column-count: auto;
      padding: 40px 0;
      ul {
        column-count: auto;
      }
    }

    &__list-item {
      font-size: 20px;
      line-height: 20px;

      &:not(:last-child) {
        margin-bottom: 44px;
      }
    }

    &__info {
      padding-bottom: 100px;
      position: relative;

      button {
        position: absolute;
        left: 0;
      }
    }
  }
}
