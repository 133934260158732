.ant-row {
  position: relative;
  width: 100%;
}

.ant-form-item-control {
  position: relative;

  .ant-input-suffix {
    position: absolute;
    right: 20px;
    top: 16px;
    display: flex;
    align-items: center;
  }
}

#new-password {
  & .ant-row:nth-child(2) {
    margin-bottom: 42px;
  }
}

@media (max-width: $mobile) {
  #new-password {
    & .ant-row:nth-child(1) {
      margin-bottom: 30px;
    }

    & .ant-row:nth-child(2) {
      margin-bottom: 46px;
    }
  }
}

input[name="password"],
input[name="approvePassword"] {
  padding: 14px 50px 14px 14px;
}

.ant-form-item-has-error input[type="email"],
.ant-form-item-has-error input[type="password"] {
  border: 2px solid #e40000;
  box-shadow: inset -1px -1px 1px rgba(255, 255, 255, 0.7),
    inset 1px 1px 2px rgba(174, 174, 192, 0.2);
  border-radius: 5px;
  color: #e40000;

  &:focus {
    color: $black;
  }
}

.ant-form-item-explain {
  position: absolute;
  @include font_small(12px, 16px, 400);
  color: #e40000;
  opacity: 0.6;
  padding-top: 5px;
}

.ant-form-item-error-explain {
  position: absolute;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  padding-top: 6px;
  color: #e40000;
  opacity: 0.6;
}

@media (max-width: $mobile) {
  .ant-form-item-explain {
    font-size: 10px;
    line-height: 12px;
  }

  .ant-form-item-error-explain {
    font-size: 12px;
    line-height: 14px;
  }
}

.ant-tooltip {
  background: #ffffff;
  box-shadow: 2.5px 2.5px 8px rgba(174, 174, 192, 0.14);
  border-radius: 11px;
  max-width: 265px;
  z-index: 100;

  &-content {
    padding: 12px 20px;
  }

  &-text {
    font-family: "OpenSans";
    @include font_small();
    font-weight: 400;
  }
}

.ant-radio-button-input {
  display: none;
}

.ant-radio-button-wrapper {
  cursor: pointer;
  @include font_small(12px);
  font-weight: 400;
  padding: 10px 20px;
  border: 2px solid transparent;

  &-checked {
    border: 2px solid #ffc212;
    border-radius: 60px;
  }
}

.ant-switch {
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 40px;
  height: 20px;
  line-height: 22px;
  vertical-align: middle;
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  background: linear-gradient(180deg, #f0f2f7 0%, #ffffff 100%);
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.1);

  &-checked {
    background: linear-gradient(180deg, #ffc212 0%, #ffc212 100%);
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.1);
  }

  &-checked .ant-switch-handle {
    left: calc(100% - 18px);
    &:before {
      background-color: #ffffff;
    }
  }
}

.ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  transition: all 0.2s ease-in-out;

  &:before {
    position: absolute;
    inset: 0;
    background-color: $active;
    border-radius: 9px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
    content: "";
  }
}

.ant-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  color: #fff;
  font-size: 12px;
  transition: margin 0.2s;
}

.ant-form-item-switch {
  display: flex;
  align-items: center;

  & .ant-form-item-label {
    order: 1;
    margin-left: 16px;
    @include font_small(14px, 20px, 700);
    color: $black;
  }
  & .ant-form-item-control {
    order: 0;
  }
}

.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $black;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  font-family: "IBM Plex Sans";
  position: relative;
  display: inline-block;
  cursor: pointer;

  &-selector {
    background: #f0f2f7;
    box-shadow: -1.75055px -1.75055px 5.25165px #ffffff,
      1.75055px 1.75055px 5.25165px rgba(174, 174, 192, 0.4);
    border-radius: 47.1945px;
  }
}

.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 11px;
}

.ant-select-single .ant-select-selector {
  display: flex;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  inset: 0 11px;
}

.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: 0 0;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  position: relative;
}

.ant-modal-root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 300;
}

.ant-modal-body {
  @include flex();
  height: 100%;
  margin: auto;
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    background: transparent;
    width: 0;
  }
}

.ant-modal-close {
  display: none;
}

.ant-modal-close-x {
  display: none;
}

.ant-modal-content {
  height: 100vh;
}

.ant-modal {
  width: 100% !important;

  &.background {
    background: rgba(17, 17, 17, 0.7);
  }
}
.general-info,
.contract-card {
  .react-select__indicators {
    color: $black !important;
  }

  .react-select__control {
    width: 201px !important;
  }

  .react-select__menu {
    background: #f0f2f7 !important;
    box-shadow: -1.66667px -1.66667px 5px #ffffff,
      2.5px 2.5px 5px rgba(174, 174, 192, 0.4) !important;
    border-radius: 8px !important;
    width: 201px !important;
    z-index: 200;
  }

  .react-select__option {
    width: 100%;
  }

  .react-select__option:hover {
    background: #82828271 !important;
  }
}

.select-block {
  .react-select__indicators {
    color: $black !important;
  }

  .react-select__menu {
    background: #f0f2f7 !important;
    box-shadow: -1.66667px -1.66667px 5px #ffffff,
      2.5px 2.5px 5px rgba(174, 174, 192, 0.4) !important;
    border-radius: 8px !important;
    width: 169px !important;
    z-index: 201;
  }

  .react-select__option:hover {
    background: #82828271 !important;
  }
}

.react-select__indicator-separator {
  display: none !important;
}

.profile-contact__select-block {
  .select-block__item {
    width: 100%;
    justify-content: space-between;
    margin: 0;
  }

  .react-select-container {
    width: 100%;
    max-width: 287px;
    margin: 0 !important;
  }

  .react-select__menu {
    margin-top: -2px;
    box-shadow: none !important;
    border-color: 1px solid white !important;
    z-index: 200;
  }

  .react-select__menu-list {
    margin-left: 16px;
    margin-right: 16px;
    border-top: 2px solid #f0f0f3;
    z-index: 200;
  }

  .react-select__option {
    padding: 12px 0;
  }
}

.new-contact__select-block {
  .select-block__item {
    width: 100%;
    justify-content: space-between;
    margin: 0;
  }

  .react-select-container {
    width: 100%;

    margin: 0 !important;
  }

  .react-select__menu {
    margin-top: -2px;
    box-shadow: none !important;
    border-color: 1px solid white !important;
    z-index: 200;
  }

  .react-select__menu-list {
    margin-left: 16px;
    margin-right: 16px;
    border-top: 2px solid #f0f0f3;
    z-index: 200;
  }

  .react-select__option {
    padding: 12px 0;
  }
}

.react-datepicker-wrapper {
  position: relative;
  & .react-datepicker__input-container {
    background: #f0f2f7;
    box-shadow: -1.75055px -1.75055px 5.25165px #ffffff,
      1.75055px 1.75055px 5.25165px rgba(174, 174, 192, 0.4);
    border-radius: 47.1945px;
  }

  & input {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    padding: 14px 8px 14px;
    &::placeholder {
      @include font(13px, 20px, 600);
      font-family: "IBMPlexSans";
      color: #111111cb;
      // padding-left: 20px;
    }
  }
}

@media (max-width: $tablet) {
  .ant-radio-group {
    margin-left: -20px;
  }
}

@media (max-width: $mobile) {
  .ant-radio-group {
    @include flex();
    text-align: center;
  }

  .ant-modal-body {
    @include flex();
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
