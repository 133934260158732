table {
  width: 100%;
  display: table;
  border-collapse: collapse;

  thead {
    border-bottom: 1px solid rgba(206, 209, 217, 0.6);
  }

  thead th {
    border-left: 1px solid rgba(206, 209, 217, 0.6);
  }

  thead td {
    border-left: none;
  }

  thead tr td {
    position: sticky;
  }

  thead tr th {
    position: sticky;
  }

  tbody tr th {
    padding: 10px 36px 10px 40px;
    background-color: #f0f2f7;
    z-index: 10;
  }

  tbody tr .table-consumption__th {
    padding-right: 20px;
  }

  tbody tr:first-child th,
  tbody tr:first-child td {
    padding-top: 20px;
  }

  & td {
    border-left: 1px solid rgba(206, 209, 217, 0.6);
  }

  th {
    @include font_small(14px, 20px, 700);
    color: #333333;
    vertical-align: middle;
  }

  td {
    @include font_small(14px);
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    padding: 8px 7px;
    text-align: center;

    & input {
      background: transparent;
    }
    &:first-child {
      text-align: start;
    }
  }
}

.table {
  &__th {
    @include font_small(14px, 20px, 700);
    padding: 16px 15px;
    text-align: left;
  }

  &__th:first-child {
    border-left: 0;
  }

  &__th-body,
  &__td {
    @include font_small(14px, 20px, 400);
    color: $black;
    padding: 13px 15px;
  }

  &__general {
    background: #f0f2f7;
    box-shadow: inset -3.14286px -3.14286px 3.14286px rgba(255, 255, 255, 0.7),
      inset 3.14286px 3.14286px 6.28571px rgba(174, 174, 192, 0.2);
    border-radius: 15.925px;
    padding: 20px;
    overflow: hidden;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 16px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 0 0 16px 16px;
      background: #ffffff;
      box-shadow: inset 0.333328px 0.333328px 1.33331px rgba(0, 0, 0, 0.19534);
    }

    &::-webkit-scrollbar-thumb {
      width: 100px;
      height: 10px;
      margin: 0 20px;
      background: #ffc212;
      box-shadow: inset 0.333328px 0.333328px 1.33331px rgba(0, 0, 0, 0.19534);
      border-radius: 16px;
      border: 3px solid white;
    }
  }
}
.table-consumption {
  white-space: nowrap;
  background: #f0f2f7;
  box-shadow: inset -3.14286px -3.14286px 3.14286px rgba(255, 255, 255, 0.7),
    inset 3.14286px 3.14286px 6.28571px rgba(174, 174, 192, 0.2);
  border-radius: 15.925px;
  padding: 20px;
  max-height: 462px;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 16px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0 0 16px 16px;
    background: #ffffff;
    box-shadow: inset 0.333328px 0.333328px 1.33331px rgba(0, 0, 0, 0.19534);
  }

  &::-webkit-scrollbar-thumb {
    width: 100px;
    height: 10px;
    margin: 0 20px;
    background: #ffc212;
    box-shadow: inset 0.333328px 0.333328px 1.33331px rgba(0, 0, 0, 0.19534);
    border-radius: 16px;
    border: 3px solid white;
  }

  &__th {
    @include font_small(12px, 20px, 600);
    padding: 12px 5px 10px;
    color: #828282;
    background: transparent;
    &:first-child {
      text-align: start;
    }
  }

  &__th:first-child {
    border-left: 0;
  }

  &__th-body,
  &__td {
    @include font_small(14px, 20px, 400);
    color: $black;
    padding: 13px 15px;
  }
}

.table-order {
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding-bottom: 0;
  overflow: visible;

  tbody tr:first-child td {
    padding-top: 0;
  }

  &__th-body {
    border-left: none;
    opacity: 0.6;
    border-top: 1px solid rgba(206, 209, 217, 0.6);
    padding-top: 28px;
    padding-bottom: 20px;
  }

  &__thead {
    .table-order__th {
      border-left: none;
      white-space: nowrap;
      vertical-align: baseline;
      text-align: center;
    }

    & .table-order__th:first-child {
      border-left: none;
      padding-left: 0;
      text-align: left;
    }
  }

  &__th {
    text-align: left;
    @include font_small(14px, 20px, 700);
    color: #828282;
    padding: 20px 15px 20px;

    &:last-of-type {
      position: relative;
      display: flex;
      justify-content: center;
    }

    & .tooltip {
      display: inline-block;
    }

    & .tooltip__text {
      right: 0%;
      bottom: 80%;
    }
  }

  &__th-body {
    text-align: left;
    @include font_small(14px, 20px, 400);
    color: $black;
    padding: 25px 0px 25px;
  }

  &__td {
    @include font_small(14px, 20px, 400);
    color: $black;
    border-left: none;
    border-top: 1px solid #ced1d9;
    padding: 0 10px;
    vertical-align: middle;
    input {
      padding-left: 0;
    }

    &--empty {
      opacity: 0.4;
    }
  }

  &__td-input {
    background: #f0f2f7;
    box-shadow: none;
  }

  &__button {
    display: none;
  }
}

.table-documents {
  &__th {
    padding: 15px 17px;
    color: #828282;
    text-align: center;
  }

  &__th:first-child {
    padding-left: 0;
    border-left: none;
    text-align: left;
  }

  &__body .table-documents__th {
    padding-left: 0;
    @include font_small(14px, 20px, 400);
    color: $black;
  }

  &__td {
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
    vertical-align: middle;

    &--file {
      cursor: pointer;
    }

    &:first-child {
      text-align: left;
    }
  }
}

.table-invoices {
  white-space: nowrap;
  &__th {
    padding: 15px 17px;
    color: #828282;
    text-align: left;
    vertical-align: top;
  }

  &__th:first-child {
    padding-left: 0;
    border-left: none;
  }

  &__body .table-invoices__th {
    padding-left: 0;
    @include font_small(14px, 20px, 400);
    color: $black;
  }

  &__td {
    padding: 15px 16px;
    text-align: left;

    &:nth-child(5) {
      white-space: normal;
    }

    &:first-child {
      text-align: left;
    }
  }
}

.table-acts {
  &__th {
    padding: 15px 28px;
    color: #828282;
    text-align: left;
  }

  &__th:first-child {
    padding-left: 0;
    padding-right: 48px;
    border-left: none;
  }

  &__th:last-child {
    padding: 15px 98px;
  }

  &__body .table-acts__th {
    padding-left: 0;
    padding-right: 20px;
    padding-top: 28px;
    @include font_small(14px, 20px, 400);
    color: $black;
  }

  &__td {
    text-align: center;
    vertical-align: middle;
    padding-top: 28px;
    padding-left: 31px;
    padding-right: 31px;
  }
}

.table-contracts {
  & .table-acts__th {
    padding: 15px 50px;
    color: #828282;
    text-align: center;
  }

  & .table-acts__th:first-child {
    padding-left: 0;
    text-align: left;
  }

  & .table-acts__th:last-child {
    padding: 15px 100px;
  }
}

.table-edit {
  white-space: nowrap;

  tbody tr:first-child td {
    padding-top: 10px;
  }

  &__th-body {
    @include font_small(12px, 16.8px, 400);
    padding: 10px 15px 10px 0;
  }

  &__thead {
    text-align: center;
    padding: 0;
    @include font_small(12px, 16.8px, 400);

    & th {
      border-left: 1px solid rgba(206, 209, 217, 0.6);
    }
  }

  &__th {
    padding: 0 16px 20px;
    &--hide {
      display: none;
    }
  }

  &__td-input {
    padding: 0 7px;

    &--hide {
      display: none;
    }
    & input {
      text-align: center;
      box-shadow: none;
      border-radius: 0;
      padding-left: 0;
      width: 45px;
    }
  }
}

.pagination {
  max-width: fit-content;
  background: #f0f0f3;
  box-shadow: -1.66667px -1.66667px 5px #ffffff,
    2.5px 2.5px 5px rgba(174, 174, 192, 0.4);
  border-radius: 8.33333px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;

  li {
    position: relative;
    align-items: center;
    padding: 10px 15px;
  }

  & a {
    @include font_small(14px, 20px, 700);
    color: #919191;
  }

  li.selected a {
    color: #f89500;
    cursor: default;
  }

  li:before {
    content: none;
  }

  li:first-child a {
    &::before {
      content: "";
      display: block;
      height: 30.67px;
      width: 1.67px;
      background: #f0f2f7;
      box-shadow: -0.333333px -0.833333px 1.66667px #ffffff,
        0.833333px 1.25px 1.66667px rgba(174, 174, 192, 0.6);
      border-radius: 3.33333px;
      position: absolute;
      right: 0;
      top: 4px;
    }
  }

  li:last-child a {
    &::before {
      content: "";
      display: block;
      height: 30.67px;
      width: 1.67px;
      background: #f0f2f7;
      box-shadow: -0.333333px -0.833333px 1.66667px #ffffff,
        0.833333px 1.25px 1.66667px rgba(174, 174, 192, 0.6);
      border-radius: 3.33333px;
      position: absolute;
      left: 0;
      top: 4px;
    }
  }

  li {
    &.disabled a {
      cursor: default;
      & path {
        stroke: #919191;
      }
    }
  }
}

@media (max-width: $tablet) {
  .pagination {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin: 20px auto 0;
    right: 0;
    left: 0;
  }

  .table {
    &__general {
      box-shadow: none;
      overflow: hidden;
      overflow-x: auto;
      padding: 0;
      // &::-webkit-scrollbar {

      // 	height: 16px;
      // }

      // &::-webkit-scrollbar-track {

      // 	border-radius: 0 0 16px 16px ;
      // 	background: #FFFFFF;
      // 	box-shadow: inset 0.333328px 0.333328px 1.33331px rgba(0, 0, 0, 0.19534);
      // }

      // &::-webkit-scrollbar-thumb {
      // 	width: 100px;
      // 	height: 10px;
      // 	margin: 0 20px;
      // 	background: #FFC212;
      // 	box-shadow: inset 0.333328px 0.333328px 1.33331px rgba(0, 0, 0, 0.19534);
      // 	border-radius: 16px;
      // 	border: 3px solid white;

      // }
    }
  }

  .table-order {
    &__thead {
      & .table-order__th {
        font-size: 12px;
      }

      & .table-order__th:first-child {
        padding-right: 40px;
      }
    }

    &__th:not(:first-child) {
      white-space: break-spaces;
      text-align: center;
      padding: 20px 45px 20px 0;
    }

    &__td {
      .button__table {
        width: 90px;
      }

      padding: 0;
      & input {
        text-align: center;
      }
    }
  }

  .table-edit {
    tr:first-child th {
      padding-left: 15px;
    }
    &__th-body {
      padding-left: 15px;
    }

    &__td-input input {
      padding-left: 0;
    }
  }

  .table-documents {
    white-space: nowrap;
    padding: 16px 20px;
  }

  .table-consumption {
    box-shadow: none;
    padding: 0;
  }
}

@media (max-width: $mobile) {
  .table {
    &__consumption {
      box-shadow: none;
      border-radius: 0;
      padding: 20px 0;
      width: calc(100% + 55px);
      margin-left: -30px;
    }
  }

  .table-order {
    &__button {
      display: block;
      cursor: pointer;
    }
    & button {
      display: none;
    }

    &__th {
      padding: 20px 25px 20px 0;
      font-size: 12px;
      line-height: 14px;
    }

    &__th:not(:first-child) {
      padding: 20px 0;
    }
  }

  .table-edit {
    margin-bottom: 300px;
  }

  .pagination {
    max-width: 100%;
    right: 0;
    justify-content: space-between;
  }
}
