.general-info {
  &__wrap {
    background: #f0f2f7;
    box-shadow: -1.75055px -1.75055px 5.25165px #ffffff,
      1.75055px 1.75055px 5.25165px rgba(174, 174, 192, 0.4);
    border-radius: 26px;
    padding: 30px;
  }

  &__top {
    @include flex(space-between, baseline);
    margin-bottom: 42px;
  }

  &__header {
    @include h3();
    font-family: "Exo2";
    font-weight: 700;
    width: 38%;
  }

  &__chart {
    background: #f0f2f7;
    box-shadow: inset -3.14286px -3.14286px 3.14286px rgba(255, 255, 255, 0.7),
      inset 3.14286px 3.14286px 6.28571px rgba(174, 174, 192, 0.2);
    border-radius: 15.925px;
    padding: 38px 20px 30px;

    .chart__wrap {
      width: 100%;
    }
  }

  &__chart-button {
    margin-top: 27px;
  }

  &__consumption {
    @include flex(space-between);
    margin-top: 49px;
    margin-bottom: 30px;

    & .css-15ite0j-control,
    & .css-17ln0jh-control {
      width: 195px !important;
    }
  }

  &__consumption-title {
    @include font(24px, 24px);
    font-family: "Exo2";
    font-weight: 700;
  }

  &__consumption-switch {
    display: flex;
    justify-content: space-between;
    width: 201px;
  }
  &__controls {
    // width: 165px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include border();
    border-radius: 7px;
    margin-left: 35px;
    padding: 0 4px;
  }

  &__measure {
    @include flex(space-between);
    margin-bottom: 16px;
  }

  &__measure-controls {
    max-width: 111px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include border();
    border-radius: 7px;
    margin-left: 49px;
    padding: 0 4px;
    margin-left: auto;
  }

  &__separator {
    width: 2px;
    height: 34px;
    background: #f0f0f3;
    margin: 0 1px;
    box-shadow: -0.2px -0.5px 1px #ffffff,
      0.5px 0.75px 1px rgba(174, 174, 192, 0.6);
    border-radius: 2px;
  }

  &__controls-section {
    background-color: $background;
    @include font_small();
    font-weight: 700;
    font-family: "Exo2";
    padding: 13px 12px;
    cursor: pointer;

    &--active {
      background-color: $background;
      @include font_small();
      font-weight: 700;
      font-family: "Exo2";
      padding: 12px;
      color: $active;
      pointer-events: none;
    }
  }

  &__table tbody th {
    overflow-x: auto;
    // position: sticky;
    // left: 0;
    // background-color: #F0F2F7;
    // z-index: 1000;
  }

  &__discount-wrap {
    @include border();
    padding: 30px;
    border-radius: 26px;
  }

  &__discount-select {
    margin-bottom: 23px;

    & .select-block {
      margin-top: 0;
    }
  }

  &__table-button {
    &--mb {
      display: none;
    }
  }
}

@media (max-width: $tablet) {
  .general-info {
    &__top {
      display: flex;
      flex-direction: column;
    }

    &__header {
      margin-bottom: 20px;
      width: 100%;
    }

    &__consumption {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__consumption-title {
      margin-bottom: 20px;
    }

    &__consumption-switch {
      width: 100%;
    }

    &__chart {
      box-shadow: none;
      border-radius: 0;
      padding: 38px 0 30px;
    }

    &__chart-button {
      width: 100%;
    }

    &__discount-select {
      margin-left: 20px;
    }

    &__discount-wrap {
      padding: 28px 0;
    }

    &__chart-button {
      & .button__excel {
        width: 100%;
      }
    }
  }
}

@media (max-width: 820px) {
  .general-info {
    &__chart {
      .chart__wrap {
        width: 600px;
      }
    }
  }
}

@media (max-width: $mobile) {
  .general-info {
    &__wrap {
      padding: 30px 10px;
    }

    &__header {
      font-size: 20px;
      line-height: 20px;
    }

    &__consumption-title {
      font-size: 20px;
      line-height: 20px;
      width: 64%;
    }

    &__table-button {
      display: none;
      &--mb {
        display: block;
        margin-top: 30px;
        & button {
          width: 100%;
        }
      }
    }

    &__measure-controls {
      justify-content: space-around;
      max-width: 150px;
      width: 100%;
    }

    &__table tbody th {
      padding: 20px 10px 0;
    }
  }
}

canvas {
  height: 253px !important;
}
