.icon {
	@include border();
	@include flex(center);
	border-radius: 8px;
	width: 50px;
	height: 50px;
	margin-bottom: 40px;

	img {
		width: 22px;
	}
	

	&__round {
		border-radius: 50%;
		width: 40px;
		height: 40px;
		margin-bottom: 8px;
		margin-left: auto;

		img {
			width: 12px;
		} 

	}


	&__dropdown{
		margin-left: 8px;
		display: flex;
		position:absolute;
		transition: 250ms;
		left: 70px;
		top: 8px;
		transform: rotate(180deg);
	
		&--open{
			margin-left: 8px;
			display: flex;
			position:absolute;
			left: 70px;
			top: 8px;
			transition: 250ms;
			transform: rotate(0);
		}
		img{
			width: 10px;
    		height: auto;
		}
	}
}



svg[data-icon="eye"],
svg[data-icon="eye-invisible"]
{
	width: 20px;
	height: 20px;
	fill: #BDBDBD;
}

.icon__nav{
	cursor: pointer;
}

.icon__nav  g path, .icon__nav  g rect{
	stroke: $gray_light;
}

._active > .icon__nav  g path,
._active > .icon__nav  g rect{
	stroke: $active;
}

@media (max-width: $tablet) {
	.icon{
		&__dropdown{
			margin-left: 8px;
			display: flex;
			position:absolute;
			left: 130px;
			top: 8px;
	
			img{
				width: 16px;
				height: auto;
			}
		
			&--open{
				margin-left: 8px;
				display: flex;
				position:absolute;
				left: 130px;
				top: 8px;
				transform: rotate(180deg);
				img{
					width: 16px;
					height: auto;
				}
			}
			
		}
	}
	
}