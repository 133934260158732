.askoe {
  &__wrap {
    padding: 35px 30px 30px;
    @include border();
    border-radius: 26px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 87px;
  }

  &__title {
    @include h3();
    font-family: "Exo2";
    font-weight: 700;
    color: $black;
  }

  & .select-block {
    margin-top: 0;
    width: 45%;
    & .select-block__item {
      width: auto;
    }
  }

  &__list {
    display: inline-block;
  }

  &__item {
    cursor: pointer;
    position: relative;
    padding: 0;
    padding-left: 50px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:before {
      content: none;
    }
  }

  &__item-parent {
    position: relative;
    @include font(18px, 20px, 700);
    margin: 10px 0;
    font-family: "Exo2";
    color: $black;
    &::after {
      content: "";
      @include icon("../../assets/points.svg");
      position: absolute;
      width: 15px;
      height: 15px;
      top: 3px;
      right: -20px;
    }
  }

  &__item-child {
    position: relative;
    @include font(18px, 20px, 700);
    margin: 10px 0;
    font-family: "Exo2";
    color: $black;
  }

  &__item-button {
    width: 40px;
    height: 40px;
    background: #f0f2f7;
    box-shadow: -2.34694px -2.34694px 7.04082px #ffffff,
      2.34694px 2.34694px 7.04082px rgba(174, 174, 192, 0.4);
    border-radius: 8px;
    @include flex();
    margin-right: 20px;
    position: absolute;
    left: 0;

    &::before {
      content: "";
      @include icon("../../assets/arrow-calendar.svg");
      width: 13px;
      height: 16px;
      transform: rotate(-90deg);
    }

    &--open {
      &::before {
        transform: rotate(90deg);
      }
    }
  }

  &__item .askoe__item {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 22px;
      height: 22px;
      left: -30px;
      top: 0;
      border-left: 3px solid #f89500;
      border-bottom: 3px solid #f89500;
      border-radius: 1px;
      background-image: none;
    }
  }

  &__item-last {
    width: 40px;
    height: 40px;
    background: #f0f2f7;
    box-shadow: -2.34694px -2.34694px 7.04082px #ffffff,
      2.34694px 2.34694px 7.04082px rgba(174, 174, 192, 0.4);
    border-radius: 8px;
    @include flex();
    margin-right: 20px;
    position: absolute;
    left: 0;

    &--plus {
      &::after {
        content: "";
        @include icon("../../assets/pluss.svg");
        width: 20px;
        height: 20px;
        transform: rotate(-90deg);
      }
    }

    &--minus {
      &::after {
        content: "";
        @include icon("../../assets/minus.svg");
        width: 20px;
        height: 20px;
      }
    }
  }
}

.askoe-modal {
  width: 100%;
  max-width: 1500px;
  margin: auto;
  position: relative;

  & .general-info__table-button {
    display: none;
  }
  &__wrap {
    @include border();
    border-radius: 26px;
  }

  &__body {
    padding: 0 30px;
  }

  &__top {
    @include flex(flex-start, center);
    padding: 34px 0 12px;
  }

  &__title {
    @include font(30px, 30px, 700);
    margin: 10px 0;
    font-family: "Exo2";
    color: $black;
  }

  &__switch {
    @include font(14px, 20px, 600);
    color: #7a7a7a;
    margin-left: 22px;
    text-decoration: underline;
    @include flex();
    & .ant-switch {
      margin-right: 11px;
    }
  }

  &__button {
    max-width: 156px;
    padding: 28px 0 24px;
    & .button {
      width: 100%;
      line-height: 16px;
      padding: 8px 0;
      z-index: 0;
    }
  }

  &__datapicker {
    position: relative;

    input[data-type="day"]::placeholder {
      position: absolute;
      top: 15px;
      left: 15px;
    }

    .datepicker__input {
      padding: 13px 4px 13px 14px;

      &--day {
        padding: 13px 4px 13px 30px;
      }
    }

    .datepicker__select {
      height: 46px;
    }

    svg {
      position: absolute;
      top: 30%;
      right: 6%;
    }
  }

  & .select-block__item {
    width: auto;

    & .askoe-modal__switch {
      margin-left: 15px;
      margin-top: 0;
    }
  }

  & .css-qc6sy-singleValue {
    @include font(14px, 20px, 700);
    font-family: "IBMPlexSans";
    color: #111111;
    text-align: left;
  }

  & .css-4ljt47-MenuList > div {
    @include font(14px, 20px, 700);
    font-family: "IBMPlexSans";
    color: #111111;
    text-align: left;
    padding-left: 25px;
  }

  &__chart {
    margin-top: 20px;
  }

  &__filter {
    margin-bottom: 10px;
  }
}
@media (max-width: $laptop) {
  .askoe-modal {
    max-width: 1180px;
  }
}

@media (max-width: $tablet) {
  .askoe {
    &__wrap {
      padding: 40px 40px 130px 40px;
    }

    &__top {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 55px;
    }

    &__title {
      margin-bottom: 24px;
      font-size: 32px;
      line-height: 32px;
    }

    & .select-block {
      & .select-block__item {
        justify-content: flex-start;
      }
    }
  }

  .askoe-modal {
    // height: inherit;
    &__top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 30px;
    }

    &__title {
      order: 1;
      font-size: 32px;
      line-height: 32px;
    }

    &__button {
      max-width: 100%;
    }

    & .select-block {
      & .select-block__item {
        display: none;
      }
    }

    & .general-info__controls {
      margin-left: 0;
      margin-bottom: 17px;
    }
  }
}

@media (max-width: $mobile) {
  .askoe {
    &__wrap {
      padding: 30px 20px 68px 20px;
    }

    &__top {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 55px;
    }

    &__title {
      margin-bottom: 24px;
      font-size: 20px;
      line-height: 20px;
    }

    & .select-block {
      width: 100%;
      padding-right: 0;
      & .select-block__item {
        width: 69%;
        justify-content: space-between;
        &.css-b62m3t-container {
          max-width: 159px;
        }
      }
    }
  }

  .askoe-modal {
    &__body {
      padding: 0 15px;
    }

    &__wrap {
      border-radius: 0;
    }

    &__title {
      font-size: 26px;
      line-height: 26px;
    }

    &__chart {
      & .general-info__chart {
        padding: 38px 0 0;
      }
    }
  }
}
