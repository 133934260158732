.pagenotFound {
	position: relative;
	display: flex;
    flex-direction: column;
    align-items: center;
	margin:200px 0;

	&__title{
		@include h1();
		margin-bottom:50px;
	}
}