html {
  height: 100%;
  width: 100%;
  font: 14px/1.5 Arial, sans-serif;
  background: $background;
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    width: 0;
    background: transparent;
  }
}

body {
  min-height: 100%;
  position: relative;
  background: $background;
  margin: 0 auto;
  font-size: 16px;
  font-family: "OpenSans", sans-serif;
  overflow-y: auto;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
  max-width: $large-laptop;
  margin: auto;
}

button {
  text-decoration: none;
  border: none;
  cursor: pointer;
  @include font_small();
  font-weight: 600;
  font-family: "OpenSans";
  position: relative;
  z-index: 100;

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    cursor: default;
    opacity: 0.7;
    box-shadow: none;
  }
}

a {
  text-decoration: none;
  color: $black;
  cursor: pointer;
  display: inline-block;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    @include flex(flex-start);
    @include font_small();
    padding-left: 40px;
    position: relative;
    font-family: "OpenSans";
    @media (max-width: $tablet) {
      padding-left: 24px;
      display: block;
    }
    &._active {
      color: $active;
    }
  }

  li::before {
    position: absolute;
    content: "";
    background-color: $active;
    border-radius: 50%;
    display: inline-block;
    width: 17px;
    height: 17px;
    left: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

input {
  width: 100%;
  border: none;
  padding: 0;
  background: #ffffff;
  box-shadow: inset -1px -1px 1px rgba(255, 255, 255, 0.7),
    inset 1px 1px 2px rgba(174, 174, 192, 0.2);
  border-radius: 5px;
  padding: 14px 0 14px 14px;
  @include font_small();
  box-sizing: border-box;

  &::placeholder {
    color: $black;
    opacity: 0.6;
    @include font_small();
  }

  &:focus-within,
  &:focus-visible {
    outline: none;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="submit"],
input[type="text"],
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  outline: none;
}

.container {
  padding: 0 130px;
  position: relative;
  min-height: calc(100vh - 100px);

  @media (max-width: $laptop) {
    padding: 0 130px;
  }

  @media (max-width: $tablet) {
    padding: 0 94px;
  }

  @media (max-width: $mobile) {
    padding: 0 15px;
  }
}

.separator {
  width: 100%;
  height: 4px;
  @include border();
  border-radius: 2px;
}

*:focus-visible {
  outline: none;
}

.tooltip {
  margin-left: 10px;
  @include flex();
  cursor: pointer;

  & svg {
    width: 20px;
    height: 20px;
  }

  &--info svg {
    width: 18px;
    height: 18px;
  }

  &__text {
    position: absolute;
    width: 200px;
    padding: 12px 20px;
    background: #ffffff;
    box-shadow: 2.5px 2.5px 8px rgba(174, 174, 192, 0.14);
    border-radius: 11px;
    @include font_small();
    color: $black;
    right: -10%;
    bottom: 135%;
    display: none;
  }

  &:hover ~ .tooltip__text {
    display: block;
    white-space: pre-wrap;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.input-file {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  font-size: 0;
  cursor: pointer;
}
