.payments{
	position: relative;

	&__info{
		position: absolute;
    	right: 0;
		top: -113px;

		.info-block{
			&__wrap{
				margin-bottom: 0;
			}

			&__title{
				color: $black;
				margin-bottom: 20px;
			}

			&__balance-state{
				@include font_small(14px, 20px, 400);
				margin-bottom: 2px;
			}

			&__balance-price{
				@include h3();
				font-family: 'Exo2';
				font-weight: 700;
				margin-bottom: 20px;
				max-width: none;
			}

			&__tariff{
				display: none;
			}
	
			.tooltip{
				position: absolute;
				right: 0;
				top: 50px;
			}

		}
		
		
		
	}

	& .react-tabs{
		margin-top: 94px;
	}
}


@media (max-width: $tablet) {
	.payments{
		&__info{
			position: relative;
			top:0;

			.info-block{
				

				&__item{
					width: 100%;
					margin: 15px;
				}

				&__balance{
					@include flex(space-between, center);
				}
				
				.tooltip{
					right: 50px;
    				top: 43px;
				}

				.button__submit{
					width: 256px;
				}
	
			}
		}

		.react-tabs{
			margin-top: 30px;
		}
	}


}


@media (max-width: $mobile) {
	.payments{
		&__info{
			position: relative;

			.info-block{
				&__items{
					margin: 15px;
				}
				
				&__item{
					margin: -15px;
				}
				
				&__balance{
					display: block;
				}
				
				.tooltip{
					right: 70px;
   					top: 34px;
				}

				.button__submit{
					width: 100%
				}
	
			}
		}

		.react-tabs{
			margin-top: 40px;
		}
	}
	
}