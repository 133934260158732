.contract-card {
  background: #f0f2f7;
  box-shadow: -1.75055px -1.75055px 5.25165px #ffffff,
    1.75055px 1.75055px 5.25165px rgba(174, 174, 192, 0.4);
  border-radius: 26px;
  margin-bottom: 30px;

  &__wrap {
    padding: 31px 15px 20px;
  }

  &__header {
    @include flex(space-between, flex-start);
    margin-bottom: 25px;
  }

  &__title {
    @include h3();
    font-family: "Exo2";
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__date {
    @include font_small(12px, 16.8px, 400);
    margin-bottom: 12px;
    color: #919191;
  }

  &__connected-point {
    @include font_small(14px, 20px, 700);
    color: #919191;
  }

  &__header-right {
    max-width: 40%;
  }

  &__tariff {
    @include font_small(14px, 19.6px, 400);
    @include flex();
    color: #919191;
    position: relative;
    margin-bottom: 7px;
  }

  &__tariff-details {
    @include font_small(14px, 19.6px, 400);
    @include flex(flex-end);
    color: #919191;
  }

  &__forget-pass {
    text-decoration: underline;
    margin-left: 20px;
  }

  &__show-more {
    position: relative;
    @include font_small(14px, 20px, 700);
    @include flex(flex-start, center);
    color: #919191;
    margin-top: 25px;
    cursor: pointer;

    &--open {
      margin-left: 12px;
      transform: rotate(180deg);
    }

    &--close {
      margin-left: 12px;
    }

    & path {
      stroke: #919191;
    }
  }

  &__info-top {
    @include flex(space-between);
    margin: 49px 0 42px;
  }

  &__info-title {
    @include font();
    font-weight: 700;
  }
}

@media (max-width: $tablet) {
  .contract-card {
    &__wrap {
      padding: 40px;
    }

    &__header-right {
      align-self: flex-end;
    }

    &__header-left {
      width: 100%;
    }

    &__title {
      font-size: 32px;
      line-height: 32px;
    }

    &__info-top {
      @include flex(space-between, flex-start);
      flex-direction: column;
    }

    &__info-title {
      font-size: 16px;
      line-height: 20px;
    }

    &__date {
      font-size: 14px;
      line-height: 19.6px;
    }

    .info-block__item {
      width: 40%;
    }
  }
}

@media (max-width: $mobile) {
  .contract-card {
    &__wrap {
      padding: 20px;
    }

    &__header {
      display: flex;
      flex-direction: column;
    }

    &__header-left {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }

    &__header-right {
      max-width: 100%;
      align-self: flex-start;
    }

    &__tariff-details {
      justify-content: flex-start;
    }

    &__date {
      order: 0;
      font-size: 14px;
      line-height: 19.6px;
    }

    &__title {
      order: 1;
      font-size: 26px;
      line-height: 26px;
      margin-bottom: 24px;
    }

    &__connected-point {
      order: 2;
    }

    &__info-title {
      font-size: 20px;
      line-height: 20px;
    }
  }
}
