.inform-modal,
.request-callback,
.order-callback,
.error-modal,
.request-act {
  &__wrap {
    width: 480px;
    margin: auto;
    @include border();
    border-radius: 26px;
    background-color: $background;
    position: relative;
  }

  &__body {
    padding: 80px 44px 40px 44px;
  }

  &__title {
    text-align: center;
    @include h2();
    font-weight: 700;
    font-family: "Exo2";
    color: $black;
    margin-bottom: 30px;

    &--align {
      text-align: start;
    }
  }

  &__text {
    text-align: center;
    @include font(14px, 20px, 400);
    color: #111111;
    margin-bottom: 20px;
  }

  &__сontent {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  & .modal__close {
    right: 15px;
    top: 15px;
  }

  &__input {
    margin-bottom: 20px;
  }
}

@media (max-width: $tablet) {
  .inform-modal,
  .request-callback,
  .order-callback,
  .error-modal,
  .request-act {
    &__wrap {
      max-width: 580px;
      width: 100%;
    }
    &__title {
      @include h3();
    }

    &__body {
      padding: 82px 40px 40px;
    }

    &__сontent {
      align-items: flex-start;
    }
  }
}

@media (max-width: $mobile) {
  .inform-modal,
  .request-callback,
  .order-callback,
  .error-modal,
  .request-act {
    &__wrap {
      max-width: 300px;
    }

    &__content {
      height: inherit;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  }
}
