.skeleton {
  width: 100%;
  height: 300px;
  border-radius: 26px;
  background: linear-gradient(90deg, #f0f2f6 10%, #e6e8ec 50%, #f0f2f6 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite ease-in-out;
}

@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: 200px 0;
  }
}
