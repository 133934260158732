.modal {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	position: fixed;
	z-index: 5000;
	background: rgba(218, 221, 230, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;

	&__close{
		width: 40px;
		height: 40px;
		background: #F0F0F3;
		box-shadow: -2.34694px -2.34694px 7.04082px #FFFFFF, 2.34694px 2.34694px 7.04082px rgba(174, 174, 192, 0.4);
		border-radius: 36px;
		@include flex();
		position: absolute;
		right: 30px;
    	top: 30px;
		cursor: pointer;

		&::before{
			content: '';
			
			@include icon('../../assets/close.svg');
			width: 15px;
			height: 15px;
			
		}
	}

	&__background {
		background: rgba(218, 221, 230, 0.6);
	}
}
