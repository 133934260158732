.datepicker{
	position: relative;
	margin: 10px;
	min-width: 169px;
	height: 46px;
	background: #F0F2F7;
	border-radius: 47.1945px;
	

	&--disabled{
		opacity: 0.6;
		pointer-events: none;
	}
	
	&__select{
		height: 44px;
		position: relative;
		cursor: pointer;
		 & svg{
			position: absolute;
			top: 30%;
    		right: 6%;
		 }
		

		&-arrow--open{
			width: 12px;
			display: flex;
			position: absolute;
			right: 12px;
			top: 48%;
			transition: 250ms;
			transform: rotate(-180deg);
			
		}

		&-arrow{
			width: 12px;
			display: flex;
			position: absolute;
			transition: 250ms;
			right: 12px;
			top: 48%;
			transition: 250ms;
			transform: rotate(0);
			
		}
	}

	&__input, input:disabled &__input{
		position: absolute;
		width: 169px;
		background: #F0F2F7;
		box-shadow: -1.75055px -1.75055px 5.25165px #FFFFFF, 1.75055px 1.75055px 5.25165px rgba(174, 174, 192, 0.4);
		border-radius: 47.1945px;
		padding: 14px 4px 14px 14px;
		@include font(14px,20px, 800);
		color: hsl(0, 0%, 20%);
		text-align: left;
		cursor: pointer;
		pointer-events: none;
	
		&--year{
			font-size: 14px;
		}

		 &::placeholder {
			@include font(13px,20px, 600);
			font-family: 'IBMPlexSans';
			color: #111111;			
			opacity: 0.6;
			text-align: left;
		 }
	}

	&__wrap{
		min-width: 169px;
		min-height: 157px;
		background: #F0F2F7;
		box-shadow: -1.66667px -1.66667px 5px #FFFFFF, 2.5px 2.5px 5px rgba(174, 174, 192, 0.4);
		border-radius: 8px;
		position: absolute;
		top: 55px;
		padding: 15px 16px 7px 16px;
		box-sizing: border-box;
		z-index: 200;
	}

	&__switch{
		font-size: 14px;
		line-height: 20px;
		font-weight: 800;
		color: $black;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		& .ant-switch{
			margin-right: 16px;
		}
	}

	input[data-type="day"]::placeholder {
		position: absolute;
		top: 15px;
		left: 15px;
	}

	&__input--day {
		padding: 13px 4px 13px 30px;
	}
}

.datepicker-year{
	position: relative;
	margin-top: 15px;
	overflow: hidden;
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 1000;
		width: 100%;
		height: 100%;
		display: block;
		pointer-events: none;
		background: linear-gradient(to bottom, rgb(240, 242, 247, 1) 0%, rgb(240, 242, 247, 0) 25%, rgba(240, 242, 247, 0) 50%, rgba(240, 242, 247, 1) 90%);
	}
	

	&__list{
		position: relative;
		height: 90px;
		overflow: scroll;
		background: transparent;  
		&::-webkit-scrollbar,&::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb  {
			width: 0;
			background: transparent;
		}
	}

	&__item{
		@include font_small(14px, 16px, 800);
		color: $black;
		margin: 10px;
		cursor: pointer;
		display: flex;
    	align-items: center;
    	justify-content: center;
		padding-left: 0;
		&::before {
			content: none;
		}

		&:first-child {
			padding-top: 20px;
		}

		&:last-child {
			padding-bottom: 20px;
		}
	}

	.react-datepicker{
		width: 100%;
		background: #F0F2F7;
		margin-top: 10px;
		border: none;
		


		&__year{
			height: 98px;
    		overflow: scroll;
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				right: 0;
				left: 0;
				z-index: 1000;
				width: 100%;
				height: 100%;
				display: block;
				pointer-events: none;
				background: linear-gradient(to bottom, rgb(240, 242, 247, 1) 0%, rgba(240, 242, 247, 0) 50%, rgba(240, 242, 247, 1) 100%);
			}
			&::-webkit-scrollbar {
				width: 0;  
				background: transparent;  
			}
		}
		 

		&  .react-datepicker-year-header, & button.react-datepicker__navigation{
			display: none ;
		}

		& .react-datepicker__year-wrapper{
			flex-direction: column;
			align-items: center;
		}

	
		& .react-datepicker__year-text {
			@include font_small(14px, 16px, 800);
			color: $black;
			margin: 7px;
		}

		& .react-datepicker__year-text--selected{
			background-color: none;
			border-radius: 0;
			&:hover{
				background: none;
			}
		}
	}
	

}

.datepicker-calendar{
	.react-datepicker{
		width: 100%;
		background: #F0F2F7;
		margin-top: 10px;
		border: none;

		& .react-datepicker__day{
			@include font_small(14px,22px,400);
			color: $black;
		}


		& .react-datepicker__header {
			border-bottom: none;
			background: #F0F2F7;
		}

		& .react-datepicker__day-name{
			@include font_small(10px,12px,400);
			letter-spacing: 0.03em;
			text-transform: uppercase;
			color: #AAAAAA;
		}

		& .react-datepicker__day {
			padding: 0.166rem;
			margin: 0
		}

		& .react-datepicker__current-month{
			@include font_small(14px,22px,600);
			margin-bottom: 19px;
		}

		& .react-datepicker__navigation-icon:before{
			border-width: 2px 2px 0 0;
		}

		& .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range){
			color: #FFFFFF;
			background: #F89500;
			box-shadow: inset 0.333328px 0.333328px 1.33331px rgba(0, 0, 0, 0.19534);
			border-radius: 106px;
		}

		& .react-datepicker__day--keyboard-selected
		{
			background: #F0F2F7;
		}

		& .react-datepicker__day--in-range{
			color: #FFFFFF;
			background: #F89500;
			border-radius: 0;
		}

		& .react-datepicker__day--range-end{
			border-radius:  0 106px  106px 0 ;

		}

		& .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--range-end{
			border-radius: 106px;	
		}

		&  .react-datepicker__day--today{
			border-radius: 106px;
			border: 1px solid #F89500;	
			&.react-datepicker__day--in-range{
				border-radius: 0 ;
				border: none;
			}

			&.react-datepicker__day--range-start{
				border-radius:  106px 0 0 106px ;
			}

			&.react-datepicker__day--range-end{
				border-radius:  0 106px  106px 0 ;
			}
		}

		& .react-datepicker__day--selected:hover, & .react-datepicker__day--in-selecting-range:hover{
			background: #F89500;
		}

		& .react-datepicker__day--in-selecting-range, 
		& .react-datepicker__month-text--selected, 
		& .react-datepicker__month-text--in-selecting-range, 
		& .react-datepicker__quarter-text--selected, 
		& .react-datepicker__quarter-text--in-selecting-range, 
		& .react-datepicker__quarter-text--in-range, 
		& .react-datepicker__year-text--selected, 
		& .react-datepicker__year-text--in-selecting-range, 
		& .react-datepicker__year-text--in-range,
		
		& .react-datepicker__day--selected,
		& .react-datepicker__day--in-selecting-range {
			background: #F89500;
			color: #FFFFFF;
			border-radius: 106px;
		}

		& .react-datepicker__day--range-start{
			color: #FFFFFF;
			background: #F89500;
			border-radius: 106px 0 0 106px;
		}
		
		
		
	}
}




