.react-tabs {
  &__tab-list {
    margin: -10px;
    @include flex(flex-start);
  }

  &__tab {
    @include border();
    @include font();
    font-weight: 700;
    border-radius: 26px;
    border: 3px solid transparent;
    color: $black;
    width: 30%;
    margin: 10px;
    padding: 15px 0;
    justify-content: center;
    position: relative;
    cursor: pointer;
    text-align: center;
    &::before {
      content: none;
    }
    &--disabled {
      border: 3px solid transparent;
      background: #f0f2f7;
      box-shadow: -1.75055px -1.75055px 5.25165px #ffffff,
        1.75055px 1.75055px 5.25165px rgba(174, 174, 192, 0.4);
      border-radius: 26px;
      color: #9b9b9b;
      cursor: default;
    }

    &--selected {
      border: 3px solid #ffc212;
      box-shadow: inset 0.333328px 0.333328px 1.33331px rgba(0, 0, 0, 0.19534);
      border-radius: 44.9697px;
      text-align: center;
      color: $black;
      cursor: pointer;
    }

    &--middle {
      width: 208px;
      padding: 15px 0;
      text-align: center;
    }

    &--small {
      @include font_small(12px, 16.8px);
      width: fit-content;
      padding: 12px 20px;
      border: none;
      box-shadow: none;
      border: 2px solid transparent;
      &::before {
        content: none;
      }
      &.react-tabs__tab--selected {
        border: 2px solid #ffc212;
        box-shadow: inset 0.333328px 0.333328px 1.33331px rgba(0, 0, 0, 0.19534);
        border-radius: 44.9697px;
      }
    }
  }

  .hidden {
    display: none;
  }

  &__tab-panel {
    margin-top: 30px;
  }
}

@media (max-width: $tablet) {
  .react-tabs {
    &__tab-list {
      overflow-x: auto;
      &::-webkit-scrollbar,
      &::-webkit-scrollbar-track,
      &::-webkit-scrollbar-thumb {
        width: 0;
        height: 0;
        background: none;
        -webkit-appearance: none;
      }
    }

    &__tab {
      min-width: 280px;
    }
  }
}

@media (max-width: 1070px) {
  .react-tabs {
    &__tab-list {
      width: calc(100% + 30%);
      margin-left: -94px;
    }

    &__tab:first-child {
      margin-left: 94px;
    }

    &__tab:last-child {
      margin-right: 94px;
    }
  }
}

@media (max-width: $mobile) {
  .react-tabs {
    &__tab-list {
      width: calc(100% + 5%);
      margin-left: -11.5px;
      margin-right: 0;
    }

    &__tab {
      min-width: auto;
      width: 100%;
      padding: 11px 30px;
      white-space: nowrap;
    }

    &__tab:first-child {
      margin-left: 17px;
    }

    &__tab:last-child {
      margin-right: 17px;
    }
  }
}
