@mixin box($margins: 0, $paddings: 0, $border: 0) {
    margin: $margins;
    padding: $paddings;
    border: $border;
}

@mixin background_image($background-image: none, $background-size: contain, $background-repeat: no-repeat) {

}

@mixin icon($background-image: none){
    display: block;
    content: '';
    background-image: url($background-image);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}
@mixin border ($color: $background){
        background: $color;
		box-shadow: -1.75055px -1.75055px 5.25165px #FFFFFF, 1.75055px 1.75055px 5.25165px rgba(174, 174, 192, 0.4);
		border-radius: 47.1945px;
}

@mixin font($size: 16px, $line_height: 20px, $font_weight: normal, $color: $black) {
    font-size: $size;
    line-height: $line_height;
    font-weight: $font_weight;
    font-family: 'OpenSans', sans-serif;
    font-style:normal;
    $color: $color;
}

@mixin font_small($size: 14px, $line_height: 20px, $font_weight: 400, $color: $black) {
    font-size: $size;
    line-height: $line_height;
    font-weight: $font_weight;
    font-family: 'OpenSans', sans-serif;
    font-style:normal;
    $color: $color;
}

@mixin h1($size: 48px, $line_height: 48px, $font_weight: 800, $color: $black) {
    font-size: $size;
    line-height: $line_height;
    font-weight: $font_weight;
    font-style:normal;
    $color: $color;
    font-family: 'Exo2';
}

@mixin h2($size: 36px, $line_height: 36px, $font_weight: 800, $color: $black) {
    font-size: $size;
    line-height: $line_height;
    font-weight: $font_weight;
    font-family: 'OpenSans', sans-serif;
    font-style:normal;
    $color: $color;
}

@mixin h3($size: 24px, $line_height: 24px, $font_weight: normal, $color: $black) {
    font-size: $size;
    line-height: $line_height;
    font-weight: $font_weight;
    font-family: 'OpenSans', sans-serif;
    font-style:normal;
    $color: $color;
}

@mixin h4($size: 20px, $line_height: 20px, $font_weight: 700, $color: $black) {
    font-size: $size;
    line-height: $line_height;
    font-weight: $font_weight;
    font-family: 'OpenSans', sans-serif;
    font-style:normal;
    $color: $color;
}

@mixin h5($size: 18px, $line_height: 18px, $font_weight: normal, $color: $black) {
    font-size: $size;
    line-height: $line_height;
    font-weight: $font_weight;
    font-family: 'OpenSans', sans-serif;
    font-style:normal;
    $color: $color;
}







@mixin size($width: auto, $height: auto) {
    width: $width;
    height: $height;
}

@mixin flex($justify-content: center, $lign-items: center) {
    display: flex;
    justify-content: $justify-content;
    align-items: $lign-items;
}

@mixin breakpoint($point) {
    @if $point == laptop {
      @media (max-width: 1440) { @content ; }
   }
     @else if $point == tablet {
      @media (max-width: 1200) { @content ; }
   }
   @else if $point == mobile {
      @media (max-width: 768px)  { @content ; }
 
   }
 }


