.documents {

	&__wrap{
		@include border();
		border-radius: 26px;
		padding: 39px 30px 20px;
	}

	&__title{
		@include h3();
		font-family: 'Exo2';
		font-weight: 700;
		color: $black;
	}

	& .select-block{
		margin-bottom: 30px;
	}

	&__contract{
		margin-top: 30px;
		padding: 20px;
		background: #F0F2F7;
		box-shadow: inset -3.14286px -3.14286px 3.14286px rgba(255, 255, 255, 0.7), inset 3.14286px 3.14286px 6.28571px rgba(174, 174, 192, 0.2);
		border-radius: 15.925px;
	}

	&__contract-heading{
		@include font(16px, 20px, 700);
		color: $black;
		margin-bottom: 16px;
	}

	&__contract-list{
		margin-top: 16px;
	}

	&__contract-item:not(:last-child){
		margin-bottom: 20px;
	}

	&__contract-title{
		display: inline-block;
		@include font(16px, 22px, 600);
		color: #F89500;
		margin-bottom: 8px;
		border-bottom: 1px solid  #F89500 ;
	}

	&__contract-date{
		@include font_small(12px, 16.8px, 300);
		color: $black;
	}

	&__notification{
		margin-top: 30px;
		padding: 20px;
		background: #F0F2F7;
		box-shadow: inset -3.14286px -3.14286px 3.14286px rgba(255, 255, 255, 0.7), inset 3.14286px 3.14286px 6.28571px rgba(174, 174, 192, 0.2);
		border-radius: 15.925px;
		@include flex(space-between)
	}

	&__notification-term{
		@include font(16px, 22.4px, 300);
		color: $black;
		margin-bottom: 8px;
	}

	&__notification-contract{
		@include font_small(12px, 16.8px, 400);
		color: $black;
	}




}


@media (max-width: $tablet) {

	.documents {

		&__wrap{
			@include border();
			border-radius: 26px;
			padding: 32px 0 0 ;
		}

		&__title{
			margin-left: 40px;
			font-size:24px;
			line-height:24px;
		}

		&__notification, &__contract{
			box-shadow: none;
			border-radius: 0;
		}

		&__notification{
			flex-direction: column;
			align-items: flex-start;

			& .button {
				width: 100%;
				max-width: 100%;
			}
		}

		&__notification-left{
			margin-bottom: 20px;
			width: 100%;
		}

		&__contract-title{
			color: $black;
			font-weight: 300;
			border-bottom: none;
		}

		&__contract-list {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		&__contract-item{
			width: 45%;
		}

		& .select-block{
			margin:30px 40px;
			padding-right: 0;
			&__item{
				display: none;
			}
		}

		.table__general{
			padding-left: 40px;
		}
	}
}


@media (max-width: $mobile) {

	.documents {

		&__contract-list {
			display: block;
		}

		&__contract-item{
			margin-bottom: 40px;
			width: 100%;
		}

		&__title{
			margin-left: 20px;
			font-size:20px;
			line-height:20px;
		}

		.table__general{
			padding-left: 20px;
		}

		& .select-block{
			margin:30px 20px;
		}
		
	}
}