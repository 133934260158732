.stub{

	&__wrap {
		padding: 54px 77px 37px;
		background-color: $background;
		@include border();
		border-radius: 26px;
	}

	&__title{
		@include h2();
		font-weight: 700;
		font-family: 'Exo2';
		color: $black;
		margin-bottom: 25px;
	}

	&__text{
		max-width: 400px;
		@include font(14px, 20px, 400);
		color: #111111;
		margin-bottom: 73px;
	}

	button {
		width: 203px;
	}
}

@media (max-width: $mobile) {
	.stub  {
		&__wrap {
			padding: 30px;
		}

		&__text{
			margin-bottom: 30px;
		}

		button {
			width: 100%;
		}
	}
}