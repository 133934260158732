.info-block{
	&__wrap{
		margin-bottom: 25px;
	}
	
	&__items{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: -20px;
	}

	&__item{
		@include border();
		border-radius: 16px;
		
		margin: 20px;
		flex-grow: 1;
		
		&:not(:first-child){
			width: 32.4%;
		}
	}

	&__item-wrap{
		padding: 14px 16px 14px 14px;
		min-height: 94px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&__title{
		max-width: 227px;
		@include font_small();
		font-weight: 700;
		color: #797979;
		@include flex(space-between);
		position: relative;
	}

	&__tariff{
		@include font_small(12px,16.8px);
		font-weight: 400;
		color: #797979;
		margin-top: 4px;
	}

	&__сontent{
		@include font();
		font-weight: 700;
	}

	&__balance-overpayment{
		color: #00BD62;
	}

	&__balance-debt{
		color: #E40000;
	}

	&__balance-equally{
		color: $black;
	}



	&__balance-price{
		max-width: 150px;
		@include font();
		font-weight: 700;
		
	}

	&__balance-state{
		max-width: 150px;
		@include font(12px);
		font-weight: 400;
	}
	
}

@media (max-width: $tablet) {
	.info-block{
		&__items{
			flex-wrap: wrap;
		}

		&__item{
			width: 42%;
			margin-bottom: 20px;
			&:not(:first-child){
				width: 42%;
			}
		}
	}
}

@media (max-width: $mobile) {
	.info-block{
		&__title{
			font-size: 12px;
			line-height: 14.4px;
		}

		&__items{
			margin: -10px;
		} 

		&__item{
			width: 35%;
			margin: 10px;
		}

		&__item-wrap{
			padding: 14px 10px;
		
		}
	}
}