.select-block{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 30px;



&__item{
	width: 560px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: -10px;

	& .css-b62m3t-container{
		margin: 10px;
	}
}

&__clear{
	display: flex;
	align-items: center;
	@include font_small();
	font-weight: 600;
	color: #7A7A7A;
	text-decoration: underline;
	position: relative;
	cursor: pointer;

	&--empty{
		cursor: default;
	}
}

&__close{
	margin-right: 8px;
	width: 30px;
	height: 30px;
	background: #F0F0F3;
	box-shadow: -2.34694px -2.34694px 7.04082px #FFFFFF, 2.34694px 2.34694px 7.04082px rgba(174, 174, 192, 0.4);
	border-radius: 36px;
	@include flex();

	

	&::before{
		content: '';
		@include icon('../../assets/close.svg');
		width: 10px;
		height: 10px;
		
	}	
	}

	&__filter{
		display: none;
	}

	&__dropdown{
		position: relative;
		margin: 10px;
		width: 169px;
		background: #F0F2F7;
		box-shadow: -1.75055px -1.75055px 5.25165px #FFFFFF, 1.75055px 1.75055px 5.25165px rgba(174, 174, 192, 0.4);
		border-radius: 47.1945px;
		cursor: pointer;
	}

	&__dropdown-wrap{
		padding: 13px 20px;
		@include font(14px, 20px, 700);
		font-family: 'IBMPlexSans';
		color: #111111;

		& .icon__dropdown{
			right: 20px;
			top: 20px;
			left: auto;
			margin-left: 0;
			transform: rotate(0deg);
			&--open{
				right: 20px;
				top: 20px;
				left: auto;
				transform: rotate(180deg);
			}
			& img{
				width: 13px;
			}
		}

		& input {
			padding: 0;
			background: none;
			box-shadow: none;
			font-weight: 700;
			pointer-events: none;

			&::placeholder {
				@include font(14px,20px, 800);
				font-family: 'IBMPlexSans';
				color: #111111;			
				opacity: 0.6;
				font-weight: 700;
			}
		}
	}

	&__dropdown-item{
		position: absolute;
		bottom: -120%;
		left: 0;
		background: #F0F2F7;
		box-shadow: -1.75055px -1.75055px 5.25165px #FFFFFF, 1.75055px 1.75055px 5.25165px rgba(174, 174, 192, 0.4);
		border-radius: 16px;
		width: 129px;
		padding: 13px 20px;
		z-index: 190;
		&:hover{
			background: #c4c5c7;
		}
	}

	& .askoe-modal__switch {
		margin-top: 5px;
    	margin-left: -25px;
	}



}


@media (max-width: $tablet) {
	.select-block{


		&__filter{
			background: #F0F2F7;
			box-shadow: -1.75055px -1.75055px 5.25165px #FFFFFF, 1.75055px 1.75055px 5.25165px rgba(174, 174, 192, 0.4);
			border-radius: 47.1945px;
			@include font_small(14px,20px, 600);
			color: $black;
			@include flex();
			padding: 11px 0;
			width: 156px;
			text-align: center;

			span{
				margin-right: 24px;
				& svg {
					width: 18px;
					height: 19px;
				}
			}
		}

		&__close{
			width: 46px;
			height: 46px;
			&::before{
				width: 16px;
				height: 16px;
				
			}
		}
	}

}


@media (max-width: $mobile) {

	.select-block {

		&__filter{
			width: 130px;
		}

		&__close{
			width: 30px;
			height: 30px;
			&::before{
				width: 10px;
				height: 10px;
				
			}
		}
	}
	
}



