@mixin font-import($name, $filename, $weight, $style, $svgID) {
    @font-face {
      font-family: $name;
      src: url('../../fonts/' + $name +'/'+ $filename + '.ttf') format('truetype');
      font-weight: $weight;
      font-style: $style;
    }
  }



@include font-import('OpenSans', 'OpenSans-Regular', normal, normal, '');
@include font-import('OpenSans', 'OpenSans-SemiBold', 600, normal, '');
@include font-import('Exo2', 'Exo2-Regular', normal, normal, '');
@include font-import('Exo2', 'Exo2-Bold', 700, normal, '');
@include font-import('Exo2', 'Exo2-ExtraBold', 800, normal, '');
@include font-import('IBMPlexSans', 'IBMPlexSans-Regular', normal, normal, '');
@include font-import('IBMPlexSans', 'IBMPlexSans-SemiBold', 700, normal, '');