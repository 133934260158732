.order-modal{
	max-width: 1180px;
	width: 100%;
	margin: auto;
	position: relative;
	


	&__wrap{
		
		@include border();
		border-radius: 26px;
	}

	&__body{
		padding: 0 30px;
	}

	&__title{
		font-size: 30px;
		line-height: 30px;
		font-family: 'Exo2';
		font-weight: 700;
		color: $black;
		padding: 34px 0 46px;
		
	}

	&__bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 0 18px;
	}

	&__buttons{
		width: 45%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		& .button__submit{
			max-width: 153px;
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
			padding: 10px 0;
		}

		& .button__excel{
			padding: 8px 18px;
			line-height: 16px;
		}
	}

	&__input{
		position: relative;
	}

	&__upload{
		@include font_small(12px, 16.8px, 400);
		color: #7A7A7A;
		text-decoration: underline;
		cursor: pointer;
		&--tb{
			display: none;
		}
	}

	&__sum{
		@include font(16px, 20px, 700);
		&--tb{
			display: none;
		}
	}

	&__table{
		padding: 27px 5px 2px 14px;
		background: #F0F2F7;
		box-shadow: inset -3.14286px -3.14286px 3.14286px rgba(255, 255, 255, 0.7), inset 3.14286px 3.14286px 6.28571px rgba(174, 174, 192, 0.2);
		border-radius: 15.925px;
		max-height: 462px;
		overflow: hidden;
    	overflow-y: auto;
		overflow-x: auto;
	&::-webkit-scrollbar {
			
		height: 16px;
	}
	   
	&::-webkit-scrollbar-track {
		
		border-radius: 0 0 16px 16px ;
		background: #FFFFFF;
		box-shadow: inset 0.333328px 0.333328px 1.33331px rgba(0, 0, 0, 0.19534);
	}
	   
	&::-webkit-scrollbar-thumb {
		width: 100px;
		height: 10px;
		margin: 0 20px;
		background: #FFC212;
		box-shadow: inset 0.333328px 0.333328px 1.33331px rgba(0, 0, 0, 0.19534);
		border-radius: 16px;
		border: 3px solid white;
		
	
	}
	}
}

@media (max-width: $tablet) {
	.order-modal{
		overflow-x: hidden;
		
		max-width: 580px;
		width: 100%;

		&__table{
			box-shadow: none;
			border-radius: 0;
			padding: 40px 0 0 0;
			margin-left: -40px;
			width: calc(100% + 80px);
			height: 100%;
		}

		&__wrap{
			
			@include border();
			border-radius: 26px;
		}

		&__body{
			padding: 0 40px;
		}

		&__title{
			font-size: 32px;
			line-height: 32px;
			padding: 66px 0 16px;
		}

		&__top-tb{
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;
		}
		&__upload{
			display: none;
			&--tb{
				display: block;
			}
		}
	
		&__sum{
			display: none;
			&--tb{
				display: block;
			}
		}

		&__bottom{
			position: relative;
			justify-content: center;
		}

		&__buttons{
			width: 100%;
			flex-direction: column;
			padding-bottom: 40px;
			& .button{
				width: 100%;
				max-width: 100%;
				margin-top: 30px;
			}

			& .button__excel{
				padding: 8px 0;

			}

		}

		&__table{
			overflow-x: auto;
		}
	}
}

@media (max-width: $mobile) {
	.order-modal{
		bottom: 0;
		right: 0;
		top: 0;
		left: 0;
		position: absolute;
		min-height: 100%;
		overflow: hidden;
		max-width: none;
		
		&__table{
			max-height: none;
			margin-left: 0;
			padding: 0;
			width: calc(100% + 20px);
			 & table{
				overflow-x: scroll;
				max-height: inherit;
			 }
			   
			&::-webkit-scrollbar-track {
				background: transparent;
				box-shadow: none;
			}
			   
			&::-webkit-scrollbar-thumb {
				background:transparent;
				border:none;
				box-shadow: none;
			}
		}

		&__wrap{
			height: 100%;
			border-radius: 0;
		}

		&__body{
			height: inherit;
			padding: 0 20px ;
		}

		&__title{
			font-size: 26px;
			line-height: 26px;
			padding-top: 77px;
			padding-bottom: 10px;
			margin: 0;
		}

		&__top-tb{
			display: flex;
			justify-content: space-between;
		}
		&__upload{
			display: none;
			&--tb{
				display: block;
			}
		}
	
		&__sum{
			display: none;
			&--tb{
				display: block;
			}
		}

		&__bottom{
			z-index: 1000;
			position: absolute;
			bottom: 0;
			margin-left: -20px;
    		width: 100%;
			justify-content: center;
			background: #F0F2F7;
			border-radius: 16px 16px 0 0;
			box-shadow: -1.75055px -1.75055px 5.25165px #FFFFFF, 1.75055px 1.75055px 5.25165px rgba(174, 174, 192, 0.4);
		}

		&__buttons{
			width: 100%;
			flex-direction: column;
			padding: 0 20px;

			& .button{
				width: 100%;
				max-width: 100%;
				margin-top: 30px;
			}

		}
	}
}