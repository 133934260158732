.profile{

	.separator{
		margin: 40px 0;

		&--tb{
			display: none;
		}
	}

	&__wrap{
		@include border();
		border-radius: 26px;
		padding: 30px;
	}

	&__block{
		display: flex;
		justify-content: space-between;

		&--details{
			margin-bottom: 60px;
		}
	}

	&__switches-block{
		width: 50%;
		margin-bottom: 60px;
	}

	&__switches-wrap{
		display: flex;
		justify-content: flex-start;
	}

	&__switch-item{
		display: flex;

		&:first-child{
			margin-right: 71px;
		}
	}

	&__block-part{
		width: 47%;
	}

	&__item{
		margin-bottom: 20px;
	}

	&__item:last-child{
		margin-bottom: 0;
	}

	&__item-user{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;

		& .profile__item{
			margin-bottom: 0;
			width: 100%;
		}
		& .profile__item:first-child{
			margin-right: 20px;
		}
	}

	&__item-text{
		min-width: 443px;
		margin-bottom: 42px;
		@include h3();
		font-family: 'Exo2';
		font-weight: 700;
	}

	&__item{
		position: relative;

		& button{
			background-color: #FFFFFF;
			padding: 6px;
			position: absolute;
			width: 122px;
			right: 6px;
			top: 6px;
		}

		.ant-input-password {
			position: relative;

			input {
				padding: 14px 50px 14px 14px;
			}
		
			.ant-input-suffix {
				display: none
			}

			
		}

		.ant-form-item-explain {
			position: relative;
			margin-bottom: -10px;
			padding: 0;
		}

		&--tooltip {
			position: absolute;
			top: 15px;
			right: 15px;
			cursor: pointer;

			&:hover::before {
				content: "Пароль має складатись із латинських літер та цифр та бути більшим за 8 символів";
				position: absolute;
				top: -100px;
				right: -50px;
				display: block;
				width: 225px;
    			height: 75px;
				border-radius: 16px;
				padding: 10px;
				background-color: #fff;
				z-index: 2;
			}
		}
	}

	&__block-title{
		@include h3();
		font-family: 'Exo2';
		color: $black;
		margin-bottom: 52px;
		font-weight: 700;

		&--details{
			margin-bottom: 32px;
		}
	}

	&__tooltip{
		@include font_small(12px, 16.34px, 300);
		padding-left: 40px;
		max-width: 75%;
		position: relative;
		margin-bottom: 44px;

		span{
			font-weight: 700;
			&:first-child{
				position: absolute;
				left: 0;
			}
		}
		
	}

	&__info{
		background: #F0F2F7;
		box-shadow: inset -1px -1px 1px rgba(255, 255, 255, 0.7), inset 1px 1px 2px rgba(174, 174, 192, 0.2);
		border-radius: 5px;
		padding: 12px 21px 12px 18px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	&__info-item:not(:last-child){
		margin-bottom:32px;
	}

	&__info-title{
		@include font_small(14px,20px, 700);
		font-family: 'Exo2';
		margin-bottom: 4px;
	}

	&__info-text{
		@include font_small(14px, 19px, 300);
	}

	&__block-part:nth-child(1){
		.profile__tooltip{
			display: none;
		}
	}

	&__button{
		& .button__full{
			width: auto;
		}
	}
	

}

.profile-contact{
	&__blocks{
		@include border();
		border-radius: 26px;
		padding: 34px 76px 34px 30px;
		margin-bottom: 30px;
		position: relative;
		@include flex(space-between, flex-start);
	}

	&__block-content{
		@include flex(space-between, center);
		flex-wrap: wrap;
		}

	&__block-title{
		@include h3();
		font-weight: 700;
		font-family: 'Exo2';
		display: flex;
		flex-direction: column;

		span{
			@include font_small(14px, 20px, 700);
			font-family: 'OpenSans';
			font-weight: 700;
			color: #111111;
			opacity: 0.6;
			margin-top: 8px;
		}
	}

	&__block-content{
		position: relative;
		max-width: 595px;
		width: 100%;

		span{
			position: absolute;
			right: -8%;
		}
	}

	&__block-delete{
		@include flex();
		@include font();
		color: #555555;
		position: absolute;
    	bottom: 30px;
		cursor: pointer;
		span{
			margin-right: 12px;
		}
	}

	&__item-user{
		@include flex( space-between,center);
		width: 30%;
		margin-bottom: 20px;
	}


	&__item{
		width: 100%;
		max-width: 595px;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		&:last-child{
			margin-bottom: 0;
		}
	}

	&__item-edit{
		&--tb{
			display: none;
		}
		&--mb{
			display: none;
		}
	}

	&__select-block {
		width: 100%;
	}


}

@media (max-width: $tablet) {
	.profile{

		.separator{
			margin: 30px 0;
			&--tb{
				display: block;
			}
		}

		&__wrap{
			padding: 40px;
		}
		&__block{
			flex-direction: column;

			&--details{
				.profile__block-part:not(:last-child){
					margin-bottom: 30px;
				}
			}
		}

		&__block-part:nth-child(1){
			.profile__tooltip{
				display: block;
				margin-bottom: 30px;
				max-width: 60%;
			}
		}

		&__block-part:nth-child(2){
			.profile__tooltip{
				display: none;
			}
		}

		&__block-part{
			width: 100%;
		}

		&__block-title{
			margin-bottom: 30px;
		}
		
		&__switches-block{
			width: 80%;
			margin-bottom: 52px;
		}

		&__button{
			.button{
				width: 100%;
			}
		}
		
	}

	.profile-contact {
		&__blocks{
			flex-direction: column;
			padding-bottom: 40px 56px  40px 40px;
		}

		&__block-title{
			margin-bottom: 30px;
		}

		&__block-delete{
			top: 40px;
			right: 28px;
			align-items: flex-start;
		}

		&__item, &__item-user{
			max-width: none;

			span{
				width: -40px;
			}
		}

		&__item-user{
			width: 47%;

			&:nth-child(3){
				width: 100%;
			}
		}


		&__item-edit{
			&--tb{
				display: block;
			}
		}

		&__button{
			.button{
				width: 100%;
			}
		}
		.select-block__item{
			display: none;
		}
	}

}

@media (max-width: $mobile) {
	.profile{

		.separator{
			margin: 40px 0;
			&--tb{
				display: block;
			}
		}

		&__wrap{
			padding: 30px 20px;
		}
		&__block{
			flex-direction: column;

			&--details:last-child {
				margin-bottom: 0;
			}
		}

		&__block-part{
			width: 100%;

			&:nth-child(1){
				.profile__tooltip{
					max-width: 100%;
				}
			}
		}

		&__block-title{
			font-size: 26px;
			line-height: 26px;
		}
	

		&__switches-block{
			width: 100%;
			margin-bottom: 52px;
		}

		&__item-user{
			flex-direction: column;
			margin-bottom: 0;

			& .profile__item:first-child{
				margin-right: 0;
			}

			& .profile__item{
				margin-bottom: 20px;
			}
		}

		&__item{
			margin-bottom: 20px;
		}

		&__item-text{
			min-width: auto;
			width: 80%;
			margin-bottom: 34px;
			@include h4();
		}

		&__switches-wrap{
			flex-direction: column;

			& .profile__switch-item:first-child{
				margin-bottom: 24px;
			}
		}

		&__switches-block{
			margin-bottom: 0;
		}

		&__button{
			bottom: -10%;
			left: 0;
			right: 0;
			background: #F0F2F7;
			box-shadow: -1.75055px -1.75055px 5.25165px #ffffff, 1.75055px 1.75055px 5.25165px rgb(174 174 192 / 40%);
			padding: 10px;
			border-radius: 26px 26px 0 0;
			position: absolute;
			// width: 100%;
			height: 88px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		
	}

	.profile-contact {
		&__item-user{
			width: 100%;
		}

		&__item-edit{
			&--mb{
				display: block;
				
			}
		}

		&__block-content{
			span{
				position: absolute;
				right: -12%;
			}
		}

		&__button{
			bottom: -11%;
			left: 0;
			right: 0;
			background: #F0F2F7;
			box-shadow: -1.75055px -1.75055px 5.25165px #ffffff, 1.75055px 1.75055px 5.25165px rgb(174 174 192 / 40%);
			padding: 10px;
			border-radius: 26px 26px 0 0;
			position: absolute;
			width: 97%;
			height: 88px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.select-block__item{
			display: none;
		}
	}

}